import { Delete, Info } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogTitle, ListItemIcon, ListItemText, MenuItem, Popover, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import LogitarApi from '../api/LogitarApi'
import CustomToolbar, { toolbarSlotProps } from '../components/CustomToolbar'
import OrderSet, { dayShortNames, dayTemplates } from '../components/OrderSet'
import { localeText } from '../misc/LocaleText'
import { contentBoxStyle } from '../styles/styles'
import { addDays } from 'date-fns'
/**
 * @brief Function to replace nulls with blanks within object and replace wanted fields with booleans
 * @param {object} object 
 * @param {array} fieldsToInput Optional: specify keys to be set to your input
 * @returns {object}
 */
const removeNulls = (object, fieldsToInput = []) => {

    let notNulls = {}

    Object.keys(object).forEach((value, i) => {

        if (fieldsToInput.includes(value)) {
            notNulls[value] = Boolean(object[value])
            return
        }

        if (object[value] === null) {

            notNulls[value] = ""
            return
        }

        notNulls[value] = object[value]
    })

    return notNulls
}

/**
 * @brief predict orders type
 * @param {object} order 
 * @returns type
 */
const predictType = (order) => {
    const editDays = JSON.parse(order.days)

    let templateType = templateCheck(editDays)

    return templateType
}

/**
 * @brief Check every template if orders days match, so the correct type may be applied to the edit screen
 * @param {array} days 
 * @returns {string} type "free" or templates name
 */
const templateCheck = (days) => {
    let type = false
    let skipFlag = true
    let templates = Object.keys(dayTemplates)

    for (let tI = 0; tI < templates.length; tI++) {
        skipFlag = true
        let currentTemplate = dayTemplates[templates[tI]]

        let prev = days[0]

        for (let i = 1; i < days.length; i++) {


            if (days[i] - prev !== 0) {
                if (currentTemplate[i] == 0) {
                    continue
                }
                return "free"
            }

            if (currentTemplate[i] === 0 && days[i] !== 0) {
                skipFlag = false
                break
            }
            prev = days[i]
        }

        if (skipFlag) { type = templates[tI]; break }

    }

    return type
}

const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'startDate', headerName: 'Aloituspäivä', width: 150, valueGetter: (params) => {
        const days = JSON.parse(params.row.days)

        const offset = days.findIndex((value) => value !== 0)

        if (offset < 0) {
            return new Date(params.row.startDate)
        }

        return addDays(new Date(params.row.startDate), offset)

    }, type: 'date' },
    //{ field: 'itemNumber', headerName: 'NimikeNo', flex: 1 },
    { field: 'name', headerName: 'Nimike', flex: 2, minWidth: 200, valueGetter: (params) => `${params.row.item}: ${params.row.name}` },
    { field: 'cargoLabel', headerName: "Tuote", flex: 1, minWidth: 100 },
    { field: 'details', headerName: 'Lisätieto', flex: 1, minWidth: 100, renderCell: (params) => {
        if(params.row.detailDays) {
            const detailDays = JSON.parse(params.row.detailDays);
            if(detailDays.length == 0)
                return params.row.details;

            const title = (
                <Table>
                    <TableBody>
                        {Object.keys(detailDays).map((key) => {
                            if(!detailDays[key] || detailDays[key] === "") 
                                return null;

                            return (
                                <TableRow key={key}>
                                    <TableCell size='small' variant='head'>{dayShortNames[key]}</TableCell><TableCell size='small'>{detailDays[key]}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            );
            return (
                <Tooltip title={title}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Info />
                        <Typography variant='caption'>Lisätiedot</Typography>
                    </Box>
                </Tooltip>
            )
        }
        else {
            return params.row.details;
        }
    } }
]

export default function Orders() {

    const [orders, setOrders] = useState([])
    const [count, setCount] = useState(0)
    const [popupState, setPopupState] = useState(false)
    const [order, setOrder] = useState()
    const [options, setOptions] = useState()
    const [contextMenu, setContextMenu] = useState(null)
    const orderidRef = useRef()

    //Dialog related functions
    const [dialog, setDialog] = useState(false)
    const { enqueueSnackbar, closeSnackbar} = useSnackbar()
    const searchParams = new URL(window.location.href).searchParams;

    const diaClose = () => {
        setDialog(false)
    }

    const diaOpen = () => {
        setDialog(true)

    }

    const handleNewOrder = () => {
        setOrder()
        setPopupState(true)
    }

    useEffect(() => {

        LogitarApi.getOrders({ extent: "list" }).then((result) => {
            console.log(result);

            setOrders(result.orders)
        })
            .catch((err) => console.log(err))

    }, [count])

    useEffect(() => {
        LogitarApi.getItems({extent: "list"}).then((result) => {
            setOptions(result.items.map((value, key) => {
                return { 
                    label: value.name,
                    shortName: value.shortName,
                    cargoName: value.cargoName,
                    cargoLabel: value.cargoLabel,
                    name: value.name,
                    id: value.id,
                    active: value.active,
                }
            }))
        })

        
        if(searchParams.get("id")) {
            openOrderEdit({
                row: {
                    id: searchParams.get("id")
                }
            });
        }
    }, [])


    const openOrderEdit = (params) => {

        orderidRef.current = params.row.id
        LogitarApi.getOrders({ id: params.row.id, extent: "all" }).then((result) => {
            if (result.status) {
                setOrder(() => removeNulls(result.orders[0], ["recurring"]))
                setPopupState(true)

            }
        })
    }

    const handleRowContextMenu = (e) => {
        e.preventDefault();

        orderidRef.current = Number(e.currentTarget.getAttribute('data-id'))
        setContextMenu({ top: e.clientY, left: e.clientX })
    }

    const deleteOrder = () => {
        if (orderidRef.current === null) return

        setDialog(false)
        

        LogitarApi.setOrder({ id: orderidRef.current }, true).then((r) => {
            console.log(r)
            orderidRef.current = null;
            setCount(prev => prev + 1)
            setPopupState(false)
            enqueueSnackbar("Tilauksen poisto onnistui")
            
        })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Tilauksen poisto epäonnistui", {variant: "error"})
                
            })
    }

    return (
        <Box sx={{
            ...contentBoxStyle, '& .MuiDataGrid-row': {
                cursor: "pointer"
            },
            height: '100%'
        }}>
            <DataGridPro
                rows={orders}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20]}
                slotProps={{
                    row: {
                        onContextMenu: (e) => handleRowContextMenu(e),

                    },
                    ...toolbarSlotProps
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "startDate", sort: "desc" }]
                    },
                    filter: {
                        filterModel: {
                            items: searchParams.has("itemId") ? [{field: 'name', operator: 'startsWith', value: `${searchParams.get("itemId")}:`}] : [],
                            quickFilterValues: [searchParams.get("search")]
                        }
                    }
                }}

                getRowClassName={(order) => {
                    if (order.row.recurring || order.row.remaining > 0)
                        return "";

                    return "row-default-grey";
                }}

                sx={{
                    width: '100%',
                    height: '100%'
                }}
                density='compact'
                disableSelectionOnClick={true}
                onRowClick={(params) => openOrderEdit(params)}
                slots={{ toolbar: () => <CustomToolbar text={"Uusi Tilaus"} onClick={handleNewOrder}></CustomToolbar> }}
                localeText={localeText}
                loading={!orders[0]}
            />

            {popupState && options &&
                
                <OrderSet exit={setPopupState} dialogOpen={setDialog} options={options} update={setCount} editOrder={order} predictType={order ? predictType(order) : "mape"} />
            }
            <Popover
                onClose={() => {
                    setContextMenu(null);
                    orderidRef.current = null;
                }}
                anchorReference={"anchorPosition"}
                open={contextMenu !== null}
                anchorPosition={contextMenu}
            >
                <MenuItem onClick={() => { setContextMenu(null); setDialog(true) }}>
                    <ListItemIcon>
                        <Delete sx={{ color: '#e0453a' }} fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: '#e0453a' }}>Poista</ListItemText>
                </MenuItem>
            </Popover>

            <Dialog
                open={dialog}
                onClose={diaClose}
            >
                <DialogTitle>
                    {"Haluatko varmasti poistaa tilauksen?"}
                </DialogTitle>

                <DialogActions sx={{justifyContent: "space-between"}}>
                    <Button onClick={diaClose}>Peru</Button>
                    <Button onClick={deleteOrder} autoFocus>
                        Kyllä
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}