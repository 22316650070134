import { Autocomplete, Box, FormGroup, MenuItem, TextField, Typography, Select, InputLabel, FormControl } from "@mui/material";
import PopupScreen from "./PopupScreen";
import LogitarApi from "../api/LogitarApi";
import { useEffect, useState } from "react";
import { ServiceMonitorType } from "../api/LogitarApiTypes";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LogiTarUserType } from "../misc/User";
import { enqueueSnackbar } from "notistack";
import AlertPop from "./AlertPop";

/***
 * @typedef {{
 *  data: import("../api/LogitarApiTypes").GetServiceMonitorRow | null,
 *  vehicle: {
*      id: number,
*      name: string,
*      licenseNumber: string,
*      kilometres: number,
*      inspectionDate: string,
*      inspectionByDate: string,
*      type: number
*   },
*   allowedVehicles?: number[],
*   userList?: {
*      id: number,
*      name: string
*   }[]
 *  onClose: () => void,
 * }} ServiceMonitorPopProps 
 */

/**
 * @type {import("./ServiceMonitorCard").ServiceMonitorMonitor}
 */
const defaultData = {
    type: ServiceMonitorType.OIL_CHANGE,
    startDate: format(new Date(), "yyyy-MM-dd"),
    serviceIntervalKm: 45000,
    firstServiceKm: 45000,
}

const monitorLabels = {
    [ServiceMonitorType.OIL_CHANGE]: "Öljynvaihto",
    [ServiceMonitorType.INTERIM_SERVICE]: "Välihuolto",
    [ServiceMonitorType.YEARLY_SERVICE]: "3v huolto",
    0: "Katsastus"
}

/**
 * 
 * @param {ServiceMonitorPopProps} props 
 * @returns 
 */
export default function ServiceMonitorPop(props) {

    const [vehicles, setVehicles] = useState([]);
    const [vehicle, setVehicle] = useState(props.vehicle);

    const [drivers, setDrivers] = useState([]);
    const [driver, setDriver] = useState(props.data ? props.data.driver : null);

    const [monitorData, setMonitorData] = useState({ ...defaultData, ...props.data });
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [saveMode, setSaveMode] = useState(null);

    useEffect(() => {
        LogitarApi.getVehicles({ extent: 'list' })
            .then((resp) => {
                if (resp.status) {
                    let veh = resp.vehicles.map(e => {
                        return {
                            id: e.id,
                            licenseNumber: e.licenseNumber,
                            type: e.type
                        }
                    })
                    if(props.allowedVehicles) {
                        veh = veh.filter(e => props.allowedVehicles.includes(e.id));
                    }

                    setVehicles(veh);
                    if(!props.vehicle) {
                        setVehicle(props.vehicle[0]);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });
        if(props.userList) {
            setDrivers(props.userList);
        }
        else {
            LogitarApi.getUsers({userType: LogiTarUserType.DRIVER})
                .then((resp) => {
                    if(resp.status) {
                        const sortedDrivers = [...resp.users].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                        setDrivers(sortedDrivers.map((e) => {
                            return {
                                id: e.id,
                                name: e.name
                            }
                        }));
                    }
                })
                .catch((e) => {
    
                })
        }
    }, []);

    /**
     * 
     * @param {string} field 
     * @param {any} value 
     */
    const onMonitorDataChange = (field, value) => {
        setMonitorData((old) => ({ ...old, [field]: value }));
    }

    const saveServiceMonitor = () => {
        /** @type {import("../api/LogitarApiTypes").SetServiceMonitorFormat} */

        setSaveMode(true);
        if (checkSaveIsValid() == false) {
            enqueueSnackbar("Täytä kaikki pakolliset kentät", { variant: "error" })
            return;
        }

        const monitor = {
            ...monitorData,
            driver: driver ? driver.id : null,
            vehicle: vehicle ? vehicle.id : null,
            startDate: format(new Date(monitorData.startDate), "yyyy-MM-dd")
        }

        if (props.existingMonitors) {
            const monitorExists = props.existingMonitors.filter((row) => row.vehicle.id == monitor.vehicle && row.type === monitor.type);
            if (monitorExists && monitorExists.length > 0) {
                const msg =   monitorLabels[monitor.type] + " on jo perustettu ajoneuvolle " + vehicle.id + ": " + vehicle.licenseNumber 
                enqueueSnackbar(msg, { variant: 'error'});
                return;
            } 
        }

        LogitarApi.setServiceMonitor(monitor, false, false)
            .then((resp) => {
                if(resp.status) {
                    enqueueSnackbar("Huoltoseuranta tallennettu");
                    props.onClose();
                }
                else {
                    enqueueSnackbar("Huoltoseurannan tallennus epäonnistui", { variant: 'error'});
                }
            })
            .catch((err) => {
                enqueueSnackbar("Huoltoseurannan tallennus epäonnistui", { variant: 'error'});
            })

    }

    const deleteMonitor = () => {
        const monitor = {
            id: monitorData.id
        }

        LogitarApi.setServiceMonitor(monitor, false, true)
        .then((res) => {
             enqueueSnackbar("Huoltoseuranta poistettu");
             setShowDeletePopup(false);
             props.onClose();
        })
        .catch((err) => {
             console.error(err)
             enqueueSnackbar("Huoltoseurannan poisto epäonnistui", { variant: "error" });
        })
    }

    const checkIsEmpty = (value) => {
        if (saveMode == null) return false;
        if (!value) {
            return true;
        }
        if (value && value.length < 1) {
          return true;
        }
        return false; 
    };

    const checkSaveIsValid = () => {
        if (vehicle == null || vehicle.length < 1 ) return false;
        if (monitorData.startDate == null || monitorData.startDate.length < 1 ) return false;
        if (monitorData.type == null || monitorData.type.length < 1 ) return false;
        if (monitorData.firstServiceKm == null || monitorData.firstServiceKm.length < 1 ) return false;
        if (monitorData.serviceIntervalKm == null || monitorData.serviceIntervalKm.length < 1 ) return false;
        return true;
    }

    return <PopupScreen
        // title={props.data  ? "Huoltoseuranta" : "Muokkaa huoltoseurantaa"}
        title={monitorData.id > 0  ? "Muokkaa huoltoseurantaa" : "Uusi huoltoseuranta"}
        onClose={props.onClose}
        onSave={saveServiceMonitor}
        onDelete={monitorData.id ? () => setShowDeletePopup(true) : undefined}
        draggable
        staticButtons
    >
        <Box sx={{
            p: 1
        }}>
            <FormControl
                sx={{
                    '& .MuiTextField-root': { mr: 1, mb: 1 },
                    '& .MuiAutocomplete-root': { mr: 1, mb: 1 },
                }}
            >
                <FormGroup row>
                    <Autocomplete
                        options={vehicles}
                        getOptionLabel={(v) => v.id ? (v.id + ": " + v.licenseNumber) : ' '}
                        isOptionEqualToValue={(o, v) => o.id === v.id}
                        value={vehicle || {id:'', licenseNumber:'',  type:''}}
                        renderInput={(params) => <TextField {...params} label="Kalusto*" />}
                        onChange={(e, v) => setVehicle(v)}
                        disabled={!!props.vehicle}
                        loading={vehicles.length === 0}
                        sx={{ width: 200 }}
                        error={checkIsEmpty(vehicle)}
                    />
                    <TextField
                        label={"Tyyppi*"}
                        sx={{ width: 200 }}
                        value={monitorData.type}
                        onChange={(e) => onMonitorDataChange("type", e.target.value)}
                        select
                        disabled={props.data && props.data.type !== undefined}
                        error={checkIsEmpty(monitorData.type)}
                    >
                        {
                            // Vetoauto: Oil/interm
                            (vehicle && vehicle.type == 1) &&
                            [
                                <MenuItem key={1} value={ServiceMonitorType.INTERIM_SERVICE}>Välihuolto</MenuItem>,
                                <MenuItem key={0} value={ServiceMonitorType.OIL_CHANGE}>Öljynvaihto</MenuItem>
                            ]
                        }
                        {
                            // Perävaunu: 3v 
                            (vehicle && vehicle.type == 2) &&
                            [
                                <MenuItem key={0} value={ServiceMonitorType.YEARLY_SERVICE}>3v huolto</MenuItem>
                            ]
                        }
                    </TextField>
                </FormGroup>
                <FormGroup row>
                    <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            name="startDate"
                            label="Aloituspäivä*"
                            inputFormat='dd.MM.yyyy'
                            sx={{ width: 200 }}
                            mask={"__.__.____"}
                            value={new Date(monitorData.startDate)}
                            onChange={(val) => onMonitorDataChange("startDate", val)}
                            renderInput={(params) => <TextField {...params} />}
                            error={checkIsEmpty(monitorData.startDate)}
                        />
                    </LocalizationProvider>
                    <Autocomplete
                        options={drivers}
                        getOptionLabel={(v) => v.id + ": " + v.name}
                        isOptionEqualToValue={(o, v) => o.id === v.id}
                        value={driver}
                        renderInput={(params) => <TextField {...params} label="Kuljettaja" />}
                        onChange={(e, v) => setDriver(v)}
                        loading={drivers.length === 0}
                        sx={{ width: 200 }}
                        ListboxProps={{
                            style: {
                                maxHeight: 200
                            }
                        }}
                    />
                </FormGroup>
                {
                    // Oil change or interim service -> kilometres 
                    (monitorData.type === ServiceMonitorType.OIL_CHANGE || monitorData.type === ServiceMonitorType.INTERIM_SERVICE) &&
                    <FormGroup row>
                        <TextField
                            label={"Huoltoväli (km)*"}
                            sx={{ width: 200 }}
                            type="number"
                            inputProps={{
                                min: 0,
                                step: 1000
                            }}
                            onChange={(e) => onMonitorDataChange("serviceIntervalKm", e.target.value)}
                            value={monitorData.serviceIntervalKm}
                            error={checkIsEmpty(monitorData.serviceIntervalKm)}
                        />
                        <TextField
                            label={"Ensimmäinen huolto (km)*"}
                            sx={{ width: 200 }}
                            type="number"
                            inputProps={{
                                min: 0,
                                step: 1000
                            }}
                            onChange={(e) => onMonitorDataChange("firstServiceKm", e.target.value)}
                            value={monitorData.firstServiceKm}
                            error={checkIsEmpty(monitorData.firstServiceKm)}
                        />
                    </FormGroup>
                }
                {
                    // Yearly service -> fixed 3v service for now
                }
                <FormGroup row>
                    <TextField 
                        label={"Selite"}
                        sx={{ width: '100%' }}
                        multiline
                        rows={4}
                        value={monitorData.details}
                        onChange={(e) => onMonitorDataChange("details", e.target.value)}
                    />
                </FormGroup>
                <FormGroup row>
                    <TextField
                        label={"Huollon suorittaja (muu kuin kuljettaja)"}
                        sx={{ width: 400 }}
                        value={monitorData.serviceByExt}
                        onChange={(e) => onMonitorDataChange("serviceByExt", e.target.value)}
                    />
                </FormGroup>
            </FormControl>
        </Box>

        {
            showDeletePopup &&
            <AlertPop
                title="Haluatko varmasti poistaa huoltoseurannan"
                cancellable
                onClose={(accepted) => {
                    if(accepted)
                        deleteMonitor();
                    else
                        setShowDeletePopup(false);
                }}
            />
        }

    </PopupScreen>
}