import { ArrowBack, ArrowForward, CallSplit, Check, Map as MapIcon, Info } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import NotesIcon from '@mui/icons-material/Notes';
import { Box, Button, CircularProgress, Divider, FormGroup, InputAdornment, Link, MenuItem, Paper, Popover, Select, Stack, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { addDays, format, isAfter, set } from 'date-fns';
import fi from 'date-fns/locale/fi';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { Navigate } from "react-router-dom";
import LogitarApi from '../api/LogitarApi';
import LogitarEvent from '../api/LogitarEvent';
import LogitarEventProvider from '../api/LogitarEventProvider';
import FuelPriceCard, { FuelType, fuelTypeList } from '../components/FuelPriceCard';
import { logoPathsSmall } from '../components/PlanningCarRow';
import { ServiceMonitorCard } from '../components/ServiceMonitorCard';
import { Loader, calculateWorkHours } from '../misc/InternalFeatures';
import { PlaySound, StopSound } from '../misc/SoundPlayer';
import LogiTarUser, { LogiTarUserType } from '../misc/User';
import '../styles/animation.css';

import AlertPop from '../components/AlertPop';
import FileManager from "../components/FileManager.tsx";
import Messages from '../views/Messages';
import JobChangePopup from './JobChangePopup';
import NewsAccept from './NewsAccept';
import UnreadMessagesPopup from './UnreadMessagesPopup';
import MapViewer from '../components/map/MapViewer';
import { checkLatLng } from '../components/MapSelector';
import Config from '../config/Config';
import { getError } from '../api/Errors';
import ErrorBanner from '../components/ErrorBanner';
import DriverFishFields from './DriverFishFields';
import FishIcon from '../assets/FishIcon';
import ColorInfo from '../components/ColorInfo';

const mainBoxStyle = {
    flexDirection: "row",
    display: "inline-flex",
    border: 1,
    p: 2,
    borderRadius: 2,
    borderColor: "#cccccc",
    height: 200,
    justifyContent: "space-between",
    mt: 2,

}

const shiftScreenStyle = {
    flexDirection: "column",
    display: "inline-flex",
    width: "auto",
    // mr: 2,
    mt: 2,
    border: 1,
    borderColor: "#cccccc",
    borderRadius: 2,
    // mb: 2,
    p: 1,
    '& .MuiButton-root ': {
        height: '40px',
        // mx: 1,
    },
    '& .MuiTypography-root ': {
        // pt: 1,
        // px: 1,

    },
}

const shiftScreenDarkStyleOverrides = {
    borderColor: "#555555",
}

const toolStyle = {
    flexDirection: "row",
    display: "inline-flex",
    py: 2,

    width: "100%",
    justifyContent: "space-around",
    mr: 2,
    flexWrap: "wrap",
    gap: 2,
    alignItems: "center",
}

const iconStyle = {
    mx: 1, mt: 1, cursor: "pointer"
}

const emphasizedCellStyle = {
    backgroundColor: 'yellow',
    fontWeight: 'bold',
}

const fishRequires = {
    "reqLoadTime": { inJob: "loadTime", label: "Lastauksen kesto", fish: true },
    "reqFishStatus": { inJob: "fishStatus", label: "Kalojen kunto", fish: true },
    "reqUnloadTime": { inJob: "unloadTime", label: "Purun kesto", fish: true },
    "reqWashLocation": { inJob: "washLocation", label: "Pesupaikka", fish: true },
    "reqWashTime": { inJob: "washTime", label: "Pesun kesto", fish: true },
}

const requires = {
    ...fishRequires,
    "reqTons": { inJob: "tons", label: "Tonnit", billingReason: 1 },
    "reqM3": { inJob: "m3", label: "Kuutiot", billingReason: 2 },
    "reqCount": { inJob: "count", label: "Kpl", billingReason: 3 },
    "reqHours": { inJob: "hours", label: "Tunnit", billingReason: 4, step: 0.25 },
    "reqWaitTime": { inJob: "waitTime", label: "Odotus (min)", step: 15 },
    "reqKm": { inJob: "kilometres", label: "Kilometrit", billingReason: 5 },
    "talenomVariants": { inJob: "talenomVariant", label: "Laji" },
    "reqDetails": { inJob: "jobDetails", label: "Lisätiedot" },
}

const fishRequireNames = {
    "loadTime": fishRequires.reqLoadTime.label,
    "fishStatus": fishRequires.reqFishStatus.label,
    "unloadTime": fishRequires.reqUnloadTime.label,
    "washLocation": fishRequires.reqWashLocation.label,
    "washTime": fishRequires.reqWashTime.label,
}

const requireNames = {
    "tons": "Tonnit",
    "tonsTare": "Taara",
    "tonsGross": "Brutto",
    "m3": requires.reqM3.label,
    "count": requires.reqCount.label,
    "hours": requires.reqHours.label,
    "waitTime": requires.reqWaitTime.label,
    "kilometres": requires.reqKm.label,
    "talenomVariant": requires.talenomVariants.label,
    "jobDetails": requires.reqDetails.label,
}

const sets = {
    "setMWh": { inJob: "MWh", label: "MWh", },
}

const nowDate = new Date();

const initializeTalenomVariants = (jobs, fullJobs = null) => {
    return jobs.map((job, i) => ({
        ...job,
        talenomVariant: (() => {
            let refJob = job;
            // In case the job object doesn't contain the required info, use refJob from fullJobs
            if (fullJobs && fullJobs[i]) refJob = fullJobs[i];
            if (refJob.item.talenomVariants && refJob.item.talenomVariants.length > 0 && refJob.talenomVariant == null) {
                const v = refJob.item.talenomVariants.find(v => v.isDefault);
                if (v && v.product) return v.product;
            }
            return refJob.talenomVariant;
        })()
    }))
}
/** 
 * @param {Set | undefined} pendingFields
 * @param {string | string[]} field 
 * @param {import('@mui/material').SxProps | undefined} sx
 */
const fieldPendingProps = (pendingFields, field, sx = undefined) => {
    if (!(pendingFields instanceof Set)) return ({
        endAdornment: undefined,
    });
    const fieldSet = Array.isArray(field) ? new Set(field) : new Set([field]);
    return ({
        endAdornment: fieldSet.intersection(pendingFields).size > 0 ?
            <InputAdornment position='end' disablePointerEvents sx={sx}>
                <CircularProgress size={20} />
            </InputAdornment>
            : undefined,
    })
}
/** 
 * @param {Set | undefined} failedFields
 * @param {string | string[]} field 
 * @param {import('@mui/material').Theme} theme
 */
const fieldFailedProps = (failedFields, field, theme) => {
    if (!(failedFields instanceof Set)) return ({
        error: undefined,
        style: undefined,
    });
    const fieldSet = Array.isArray(field) ? new Set(field) : new Set([field]);
    return ({
        error: fieldSet.intersection(failedFields).size > 0,
        style: fieldSet.intersection(failedFields).size > 0 ?
            { color: theme.palette.error.main } : undefined,
    })
}

export default function VehicleView() {
    const [vehicles, setVehicles] = useState([])
    const [vehicleNumber, setVehicleNumber] = useState(LogiTarUser.current.vehicle ? LogiTarUser.current.vehicle.id : null)
    const [proceed, setProceed] = useState(LogiTarUser.current.vehicle !== null)
    const [shiftDate, setShiftDate] = useState(nowDate)
    const [shift, setShift] = useState((nowDate.getHours() >= 3 && nowDate.getHours() < 15) ? false : true)
    const [shiftProgress, setShiftProgress] = useState({ startTime: null, endTime: null, shift: null, date: null })
    const [fuelInfo, setFuelInfo] = useState({ fuel: null, kilometres: null, type: null });
    const [lastSentFuelInfo, setLastSentFuelInfo] = useState({ fuel: null, kilometres: null, type: null });
    const [prevFuelInfo, setPrevFuelInfo] = useState({ fuel: null, kilometres: null });
    const [fuelWarningText, setFuelWarningText] = useState(/** @type {string|null} */(null));
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(1)

    const [planInfosNotif, setPlanInfoNotifs] = useState([])
    const [planNotifJobs, setPlanNotifJobs] = useState([])
    const nextDriver = useRef("")
    const [jobs, setJobs] = useState([])
    const [unseenJobs, setUnseenJobs] = useState([])
    // Fetch from a certain range the first time
    const [initialFetch, setInitialFetch] = useState(true);
    const [allowSendTooltips, setAllowSendTooltips] = useState([""]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    //Popover functions and state for multiple popovers, job instructions
    const [anchorEl, setAnchorEl] = useState({ anchorEl: null, popoverId: null });
    const [vehicleMonitor, setVehicleMonitor] = useState(/** @type {import('../components/ServiceMonitorCard').ServiceMonitorVehicle} */(null));
    const [vehicleMonitorTrailer, setVehicleMonitorTrailer] = useState(/** @type {import('../components/ServiceMonitorCard').ServiceMonitorVehicle} */(null));
    const [rows, setRows] = useState([])
    const [newsPopup, setNewsPopup] = useState(false)
    const [searchTimeStamp, setSearchTimeStamp] = useState("");
    const [chains, setChains] = useState([])
    const [unseenChains, setUnseenChains] = useState([])
    const [messagesPopup, setMessagesPopup] = useState(false)
    const [messageEvents, setMessageEvents] = useState([]);
    const [messageChainOpen, setMessageChainOpen] = useState(null);
    const [messagesWaitToAccept, setMessagesWaitToAccept] = useState(0);
    const [deletedJob, setDeletedJob] = useState([])
    const [deletedSplitJob, setDeletedSplitJob] = useState(null)
    const [futureDelete, setFutureDelete] = useState(false);
    const [futureJobs, setFutureJobs] = useState([]);
    const [events, setEvents] = useState([]);
    const manualFetchInterval = useRef(null);
    // Events reference for simultaneous events
    const eventsRef = useRef(events);
    const [prevLatestId, setPrevLatestId] = useState(0)
    const [unseenMessagesPopup, setUnseenMessagesPopup] = useState(false)
    const [arrivalFiles, setArrivalFiles] = useState([])
    const [departureFiles, setDepartureFiles] = useState([])
    const [itemsFiles, setItemsFiles] = useState([])

    const [jobDetailsSelectedJob, setJobDetailsSelectedJob] = useState(/** @type {{focus?: {lat: number, lng: number}, job: PlanningJob}} */(null));
    const [jobDetailsMapAnchor, setJobDetailsMapAnchor] = useState(null);

    const [openTonsInput, setOpenTonsInput] = useState(-1);
    const [tonsAnchorEl, setTonsAnchorEl] = useState();

    const [shiftLock, setShiftLock] = useState(false);

    const [jobsSent, setJobsSent] = useState(false);
    const [tryingToEnd, setTryingToEnd] = useState(false);
    const [shiftStarting, setShiftStarting] = useState({ startTime: true, endTime: true });

    const [shiftActionPop, setShiftActionPop] = useState({ open: false, mode: "" });

    const [endedShift, setEndedShift] = useState(false);
    const theme = useTheme();
    const smBreakPoint = useMediaQuery(theme.breakpoints.up("sm"));

    const [incompleteShiftActive, setIncompleteShiftActive] = useState(null);

    const [pendingFields, setPendingFields] = useState({});
    const [failedFields, setFailedFields] = useState({});
    const [refreshMonitors, setRefreshMonitors] = useState("");

    const handleInfoClick = (event, id) => {
        setPlanInfoNotifs(prev => prev.filter(notif => notif !== id))
        setAnchorEl({ anchorEl: event.currentTarget, popoverId: id })
    }

    const handleInfoClose = () => {
        setAnchorEl({ anchorEl: null, popoverId: null })
    }
    ///

    //Popover functions and state for item instructions
    const [anchorElItem, setAnchorElItem] = useState({ anchorEl: null, popoverId: null });

    const handleInfoClickItem = (event, id) => {
        setAnchorElItem({ anchorEl: event.currentTarget, popoverId: id })

        const tempJob = jobs.find(e => e.id === id)
        let tempItem = tempJob.item

        LogitarApi.getItems({ id: tempItem.id, extent: "allfile" })
            .then((resp) => {
                console.log("Saatu nimike", resp.items[0])
                setItemsFiles(resp.items[0].files)
            })
            .catch((err) => {
                console.log("Nimikkeen haku epäonnistui", err)
            })
    }

    const handleInfoCloseItem = () => {
        setAnchorElItem({ anchorEl: null, popoverId: null })
    }
    ///

    //Popover functions and state for Area instructions
    const [anchorElArea, setAnchorElArea] = useState({ anchorEl: null, popoverId: null });

    const handleInfoClickArea = (event, id) => {
        setAnchorElArea({ anchorEl: event.currentTarget, popoverId: id })

        const tempJob = jobs.find(e => e.id === id)
        let tempItem = tempJob.item

        LogitarApi.getAreas({ extent: 'all', id: tempItem.arrival.id })
            .then((resp) => {
                console.log("Saatu purkuarea", resp.areas[0])
                setArrivalFiles(resp.areas[0].files)
            })
            .catch((err) => {
                console.log("Purkualueen haku epäonnistui", err)
            })

        LogitarApi.getAreas({ extent: 'all', id: tempItem.departure.id })
            .then((resp) => {
                console.log("Saatu lastausarea", resp.areas[0])
                setDepartureFiles(resp.areas[0].files)
            })
            .catch((err) => {
                console.log("Latausalueen haku epäonnistui", err)
            })
    }

    const handleInfoCloseArea = () => {
        setAnchorElArea({ anchorEl: null, popoverId: null })
    }
    ///

    //Start button function
    const handleDriver = (e) => {

        e.preventDefault()

        if (vehicleNumber === "") {
            return
        }

        LogitarApi.setUser({ id: LogiTarUser.current.info.id, currentVehicle: Number(vehicleNumber) });

        jobFetch()
        incompleteShiftCheck()

    }

    const incompleteShiftCheck = () => {
        if (!LogiTarUser.current.info.currentShift) {
            return
        }

        LogitarApi.getWorkHours(null, null, null, LogiTarUser.current.info.currentShift).then((r) => {

            if (r.workhours.length < 1) {
                return
            }

            const wh = r.workhours[0]

            if (wh.date !== format(shiftDate, "yyyy-MM-dd") || (wh.date === format(shiftDate, "yyyy-MM-dd") && wh.shift !== convertShift())) {
                setIncompleteShiftActive(wh)
            }
        })
    }

    //Tons dialog
    const handleOpenTonsDialog = (/** @type { MouseEvent }*/event, id) => {
        setTonsAnchorEl(event.currentTarget);
        setOpenTonsInput(id);
    }

    const handleCloseTonsDialog = () => {
        setOpenTonsInput(-1);
    }

    //get jobs by plans, update wanted info to them
    const updateJobs = (joblist = null) => {

        if (!joblist) {
            //use jobs hook
            return
        }

        LogitarApi.setJobs(joblist).then((result) => {
        })
            .catch((err) => console.error(err))
    }

    const fuelFetch = () => {

        LogitarApi.getFuel(vehicleNumber, format(shiftDate, "yyyy-MM-dd"), convertShift(shift))
            .then((data) => {
                if (data.status) {
                    if (data.fuel.current) {
                        // User has set data for this shift
                        setFuelInfo({
                            fuel: data.fuel.current.fuel === null ? "" : String(data.fuel.current.fuel),
                            kilometres: data.fuel.current.kilometres === null ? "" : String(data.fuel.current.kilometres),
                            type: data.fuel.current.fuelType === null ? 0 : Number(data.fuel.current.fuelType),
                        });
                        setLastSentFuelInfo({
                            fuel: String(data.fuel.current.fuel),
                            kilometres: String(data.fuel.current.kilometres),
                            type: Number(data.fuel.current.fuelType),
                        });
                    }
                    else {
                        // Reset current fuel 
                        setFuelInfo({
                            fuel: "",
                            kilometres: "",
                            type: 0,
                        });
                        setLastSentFuelInfo({
                            fuel: "",
                            kilometres: "",
                            type: 0,
                        });
                    }

                    if (data.fuel.previous) {
                        // Previous data has been found
                        setPrevFuelInfo({
                            fuel: data.fuel.previous.fuel === null ? "" : String(data.fuel.previous.fuel),
                            kilometres: data.fuel.previous.kilometres === null ? "" : String(data.fuel.previous.kilometres)
                        });
                    }
                    else {
                        // Reset previous fuel
                        setPrevFuelInfo({
                            fuel: "",
                            kilometres: ""
                        });
                    }
                }
                else {
                    if (data.error === 0x26) {
                        // DB not found error, ignore

                    }
                    else {
                        // Real error
                        console.warn("Error while fetching fuel: " + data.error);
                    }
                }
            })
            .catch((err) => {
                console.warn(err);
            })

    }

    useEffect(() => {

        console.log(shiftLock, LogiTarUser.current.info, shiftDate, convertShift())

        if (!proceed) {
            return
        }

        setShiftLock(false)
        setShiftStarting(() => ({ startTime: true, endTime: true }))

        LogitarApi.getWorkHours(null, null, LogiTarUser.current.info.id, null, shiftDate, convertShift()).then((r) => {
            console.log(r)
            if (r.workhours.length === 0) {
                if (LogiTarUser.current.info.currentShift !== null) {
                    setShiftLock(true)
                    return
                }

                console.log("No shift found")
                setShiftProgress({ startTime: null, endTime: null })

            } else {

                const _wh = r.workhours[0].workhours.find((wh) => Number(wh.vehicle) === Number(vehicleNumber))

                if (!_wh && !LogiTarUser.current.info.currentShift) {
                    console.log("Can start shift")
                    setShiftProgress({ startTime: null, endTime: null })

                } else if (_wh && LogiTarUser.current.info.currentShift !== null && _wh.id !== LogiTarUser.current.info.currentShift) {
                    console.log("currentshift found matching")
                    setShiftLock(true)

                } else {
                    setShiftProgress({ ..._wh })
                }

            }

            setShiftStarting(() => ({ startTime: false, endTime: false }))
        })
            .catch((e) => {
                console.error(e)
            }).finally(() => {
                setShiftStarting(() => ({ startTime: false, endTime: false }))
            })

    }, [shift, shiftDate, proceed])

    const jobFetch = () => {

        const startDate = new Date();
        const endDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        endDate.setDate(endDate.getDate() + 1);

        (
            initialFetch ?
                LogitarApi.getPlanningRange(startDate, endDate, vehicleNumber) :
                LogitarApi.getPlanning(shiftDate, convertShift(), vehicleNumber)
        ).then((result) => {

            let readyJobs = result.vehicles[0].jobs.sort((a, b) => a.slot - b.slot).filter((j) => j.state > 0)

            //Initialize waitTime 0
            readyJobs = readyJobs.map(job => ({
                ...job,
                waitTime: (job.item.reqWaitTime == 1 && job.waitTime == null) ? 0 : job.waitTime
            }));

            //Initialize talenomVariant to isDefault variant if present
            readyJobs = initializeTalenomVariants(readyJobs);

            const planNotifs = []

            const ndDate = shift ? addDays(shiftDate, 1) : shiftDate
            //Inverted for next shift
            const ndShift = shift ? "A" : "I"

            nextDriver.current = result.vehicles[0].vehicle.driverShifts.find(ds => ds.date === format(ndDate, "yyyy-MM-dd") && ds.shift === ndShift)

            if (readyJobs.length < 1) {

                setJobs()

            } else {
                if (jobs && jobs.length > 0) {
                    readyJobs.forEach((job) => {
                        const existing = jobs.find((oldJ) => oldJ.id === job.id)

                        if (existing && existing.planDetails !== job.planDetails) {
                            planNotifs.push(job.id)
                            planNotifJobs.push(job)
                        }
                        else if (!existing && job.planDetails) planNotifs.push(job.id);
                    })
                }
                else {
                    readyJobs.forEach((job) => {
                        if (job.planDetails) planNotifs.push(job.id);
                    })
                }

                setUnseenJobs(readyJobs.filter(e => e.state === 1));

                let currentShiftJobs = readyJobs.filter(e =>
                    (format(shiftDate, "yyyy-MM-dd") === e.date && convertShift() === e.shift) ||
                    (format(shiftDate, "yyyy-MM-dd") === e.jobSplit.date && convertShift() === e.jobSplit.shift &&
                        !(e.draft && !Array.isArray(e.draft) && e.draft['>hasJobSplit'] === 1))
                );

                const tempJobs = []

                currentShiftJobs.forEach((value, key) => {
                    if (!!shiftProgress.startTime && !value.user) {
                        tempJobs.push({ id: value.id, user: LogiTarUser.current.info.id })
                    }
                })

                /**
                 const dJobs = []
 
                 currentShiftJobs.forEach((value, key) => {
                     if (value.draft && !Array.isArray(value.draft) && value.draft['>deletedSent'] === true) {
                         dJobs.push(value)
                     }
                 })
                 setDeletedJob(dJobs)                
                 * 
                 */


                //only one job can be split for a shift
                const splitJob = currentShiftJobs.find(e => e.jobSplit.id && e.jobSplit.date === format(shiftDate, "yyyy-MM-dd") && e.jobSplit.shift === convertShift());

                if (splitJob) {

                    currentShiftJobs = [...[splitJob], ...currentShiftJobs.filter((e) => e.id !== splitJob.id)]

                    if (splitJob.continuedBy !== null) {
                        LogitarApi.setJobSplit({ id: splitJob.jobSplit.id, continuedBy: LogiTarUser.current.info.id }).then((result) => {
                        })
                    }

                }

                let jobsWereSent = true

                //Setting sent state
                const jobsWithoutSplit = currentShiftJobs.filter((e) => e.jobSplit.id === null || (e.jobSplit.date === format(shiftDate, "yyyy-MM-dd") && e.jobSplit.shift === convertShift()))

                if (jobsWithoutSplit.length > 0) {

                    for (let i = 0; i < jobsWithoutSplit.length; i++) {
                        if (jobsWithoutSplit[i].checkState < 2) {
                            jobsWereSent = false
                            break
                        }
                    }

                } else {
                    jobsWereSent = false
                }

                console.log(jobsWereSent, jobsWithoutSplit)
                setJobsSent(jobsWereSent)


                //Remove jobSplits from currentShiftJobs, if hasJobSplit has only been drafted
                currentShiftJobs.forEach((value, key) => {
                    if (value.draft && !Array.isArray(value.draft) && value.draft['>hasJobSplit'] === 1) {
                        value.jobSplit = {
                            id: null,
                            date: null,
                            shift: null,
                            continuedBy: null,
                            date: null,
                            hoursRatio: null,
                            id: null,
                            shift: null,
                            switchLocation: null,
                            vehicle: null
                        }
                    }
                })

                if (tempJobs.length > 0) updateJobs(tempJobs);

                if (currentShiftJobs.length < 1) {
                    setJobs()
                } else {
                    setJobs(currentShiftJobs)
                }
            }

            if (planNotifs.length > 0 && !planNotifs.every(id => planInfosNotif.includes(id))) {
                PlaySound("ring")
            }
            setPlanInfoNotifs(prev => ([...prev, ...planNotifs]))

            setLoading(false)
            setProceed(true)

        }).catch((err) => {
            console.error(err);
            if (LogitarApi.fetchFailed) {
                enqueueSnackbar("Kuormien lataaminen epäonnistui", { variant: "error" })
            } else {
                enqueueSnackbar("Kalustoa ei löytynyt", { variant: "error" })
            }
        })

        setInitialFetch(false);

    }

    const convertShift = () => {
        return shift ? "I" : "A"
    }

    const handleButton = (val) => {

        setLoading(true)

        if (val < 0) {
            if (shift) {
                setShift(false)
            } else {
                setShift(true)
                setShiftDate(addDays(shiftDate, val))
            }

            return
        }

        if (shift) {
            setShiftDate(addDays(shiftDate, val))
        }

        setShift(prev => !prev)
    }

    const getUnCheckedJobsCount = () => {
        let unCheckedJobsCount = 0;

        if (jobs) {
            jobs.forEach((job, i) => {
                if (
                    (job.checkState > 1 || job.unloadTime === "") ||
                    (job.jobSplit.id && !(job.jobSplit.date === format(shiftDate, "yyyy-MM-dd") && job.jobSplit.shift === convertShift()))
                ) {
                } else {
                    unCheckedJobsCount++;
                }
            })
        }
        return unCheckedJobsCount;
    }

    const updateSendButtonErrors = () => {
        let errors = [];

        if ((fuelInfo.fuel === null || fuelInfo.fuel === "") && !LogiTarUser.current.isContractor()) {
            // Fuel must be set
            errors.push("Polttoaine");
        }
        if ((fuelInfo.kilometres === null || fuelInfo.kilometres === "") && !LogiTarUser.current.isContractor()) {
            // FuelInfo must be inserted
            errors.push("Kilometrit");
        }
        if ((prevFuelInfo.kilometres && (Number(prevFuelInfo.kilometres) >= Number(fuelInfo.kilometres))) && !LogiTarUser.current.isContractor()) {
            // Kilometres must be more than last row
            errors.push("Kilometrit (min. " + prevFuelInfo.kilometres + " km)")
        }


        if (jobs) {
            jobs.forEach((job, key) => {


                //If job is split from this shift, then don't take it into account
                if (job.jobSplit.id && !(job.jobSplit.date === format(shiftDate, "yyyy-MM-dd") && job.jobSplit.shift === convertShift())) {
                    return;
                }

                //Find plans item info
                const item = job.item

                if (item.itemType === "fish") {
                    Object.keys(fishRequires).forEach((field) => {
                        if (item.itemFish && job.jobFish) {
                            if (convertChecked(item.itemFish[field]) && (job.jobFish[fishRequires[field].inJob] === null || job.jobFish[fishRequires[field].inJob] === "")) {
                                errors.push(`[${key + 1}] ${fishRequires[field].label}`);
                            }
                            if (convertChecked(item.itemFish[field]) && fishRequires[field].inJob === "fishStatus" && Array.isArray(job.jobFish?.fishStatus) && job.jobFish?.fishStatus?.length < 1) {
                                errors.push(`[${key + 1}] Kalojen kunto: Tallenna vähintään yksi rivi`);
                            }
                        }
                    })
                }

                Object.keys(requires).forEach((field, k) => {
                    if (convertChecked(item[field]) && (job[requires[field].inJob] === null || job[requires[field].inJob] === null || job[requires[field].inJob] === "")) {
                        errors.push(`[${key + 1}] ${requires[field].label}`);
                    }
                    if (convertChecked(item[field]) && requires[field].inJob === "tons" && Number(job.tons) < 0) {
                        errors.push(`[${key + 1}] Tonnit negatiiviset (tarkista Taara ja Brutto)`);
                    }
                    if (field === "talenomVariants" && item[field] && item[field].length > 0 && job[requires[field].inJob] === null) {
                        errors.push(`[${key + 1}] ${requires[field].label}`);
                    }
                });

                if (!job.loadTime) {
                    errors.push(`[${key + 1}] Ei aloitettu`);
                }
                if (!job.unloadTime) {
                    errors.push(`[${key + 1}] Ei lopetettu`);
                }
            });
        }

        setAllowSendTooltips(errors);

        return errors.length > 0;
    }

    const SendButtonTooltipContent = () => {

        if (allowSendTooltips.length === 0)
            return null;

        return <div>
            <span>Seuraavat tiedot puuttuu:</span>
            <div style={{ paddingLeft: 4 }}>
                {
                    allowSendTooltips.map((e, i) => {
                        return <li key={i}>{e}</li>
                    })
                }

            </div>
        </div>
    }

    useEffect(() => {
        updateSendButtonErrors()
    }, [jobs, fuelInfo])

    useEffect(() => {

        if (!proceed) {
            return
        }

        //Fetch jobs/gigs, set new fetching when the shift or/and day is changed, set ShiftProgress aswell (not always reset)
        jobFetch();
        //TODO viestien haku
        // chainsFetch();

    }, [shift, shiftDate, count, vehicleNumber])

    useEffect(() => {
        fuelFetch();
    }, [shift, shiftDate, vehicleNumber]);

    useEffect(() => {
        if (unseenJobs.length > 0 || deletedJob.length > 0 || futureJobs.length > 0 || planNotifJobs.length > 0)
            PlaySound("ring", true);
        else if (unseenJobs.length === 0 && deletedJob.length === 0 && futureJobs.length === 0 && planNotifJobs.length === 0)
            StopSound("ring");
    }, [unseenJobs, deletedJob, futureJobs, planNotifJobs]);

    useEffect(() => {

        let _subId = null;

        setVehicles(LogiTarUser.current.info.vehicles);

        console.log(LogiTarUser.current.info.vehicles)

        _subId = LogiTarUser.current.addChangeListener(() => {
            // On user changed
            if (LogiTarUser.current.vehicle && vehicleNumber !== LogiTarUser.current.vehicle.id) {
                setVehicleNumber(LogiTarUser.current.vehicle.id);
            }
        })

        const _interval = setInterval(() => {
            if (!LogitarEvent.isConnected)
                setCount(prev => prev + 1)

        }, 15000);

        return () => {
            if (_subId !== null) {
                LogiTarUser.current.removeChangeListener(_subId);
            }
            if (_interval != null) {
                clearInterval(_interval);
            }
        }
    }, [])

    useEffect(() => {
        // Message events
        if (messageEvents.length < 1) return;
        messageEvents.forEach(event => {
            if (event.data.type === "sent") {
                getUnreadMessages();
            }
        })
        setMessageEvents([]);
    }, [messageEvents])

    useEffect(() => {
        if (proceed) getUnreadMessages();
    }, [proceed]);

    useEffect(() => {
        if (!messagesPopup) setMessageChainOpen(null);
    }, [messagesPopup])

    useEffect(() => {
        // Parse Events
        if (events.length === 0)
            return;

        // Return if not selected vehicle
        if (!proceed)
            return;

        const deletedJobs = []
        let uj = [...unseenJobs];
        const newFutureJobs = [];

        const handledEvents = [];

        let nJobs = jobs ? jobs : [];

        for (let ev of events) {
            // Add event id to handled events
            handledEvents.push(ev.id);

            const source = ev.source, data = ev.data;

            console.log("Event " + source, data);

            //Get next shifts driver
            const ndDate = shift ? addDays(shiftDate, 1) : shiftDate
            //Inverted for next shift
            const ndShift = shift ? "A" : "I"

            if (source === "jobsplit") {

                const job = data.job;
                const jobsplit = data.jobsplit;

                const refJob = nJobs.find(e => e.id === job.id)

                if (refJob) {

                    if (jobsplit.id && jobsplit.del) {
                        //Delete jobSplit

                        if (jobsplit.date === format(shiftDate, "yyyy-MM-dd") && jobsplit.shift === convertShift()) {
                            const _nj = nJobs.filter((j, i) => j.id !== job.id)
                            nJobs = _nj
                        } else {
                            refJob.jobSplit = {
                                id: null,
                                date: null,
                                shift: null,
                                continuedBy: null,
                                switchLocation: null,
                                vehicle: null
                            }

                            uj.push(refJob)
                        }
                    } else {
                        refJob.jobSplit = { ...jobsplit }

                        uj.push(refJob)
                    }
                }
                continue
            }

            //Handle driver shift event
            if (source === "drivershift") {

                if (data.id && data.del) {
                    if (Number(data.vehicle) === Number(vehicleNumber)) {
                        //Delete nextDriver
                        nextDriver.current = undefined
                    }
                    continue
                }

                const nd = data.find((e) => e.date === format(ndDate, "yyyy-MM-dd") && e.shift === ndShift)

                if (nd && Number(nd.vehicle) === Number(vehicleNumber)) {
                    nextDriver.current = nd
                }

                continue
            }

            if (data.id) {
                //Data is single object
                if (data.del) {
                    //Data has delete set true
                    if (nJobs.length > 0) {
                        setFutureDelete(false);

                        const removeIndex = nJobs.findIndex(j => j.id === data.id)

                        if (removeIndex > -1) {
                            deletedJobs.push(nJobs[removeIndex])
                            let _nj = nJobs.filter((j, i) => i !== removeIndex)
                            nJobs = _nj
                        }
                        else if (data.job) {
                            setFutureDelete(true);
                            deletedJobs.push(data.job);
                        }
                    } else if (data.job) {
                        setFutureDelete(true);
                        deletedJobs.push(data.job);
                    }
                    continue
                }
            }

            const planNotifs = []
            const planNotifJobs = []
            // Loop through vehicles
            if (data[LogiTarUser.current.vehicle.id]) {

                const eventJobs = data[LogiTarUser.current.vehicle.id].jobs

                for (let j in eventJobs) {
                    const newJob = eventJobs[j]
                    const existingIndex = nJobs.findIndex(e => e.id == newJob.id)

                    if (existingIndex >= 0) {

                        if (newJob.planDetails !== nJobs[existingIndex].planDetails) {
                            planNotifs.push(newJob.id)
                            planNotifJobs.push(newJob)
                        }

                        nJobs[existingIndex] = { ...newJob }

                    } else {
                        if (newJob.planDetails) planNotifs.push(newJob.id);
                        nJobs.push(newJob)
                    }
                }
                
                //Filter by date, shift and state
                let readyJobs = nJobs.sort((a, b) => a.slot - b.slot).filter((j) => j.state > 0 &&
                    (format(shiftDate, "yyyy-MM-dd") === j.date && convertShift() === j.shift) ||
                    (format(shiftDate, "yyyy-MM-dd") === j.jobSplit.date && convertShift() === j.jobSplit.shift)
                )

                // Future jobs
                let fJobs = nJobs.sort((a, b) => a.slot - b.slot).sort((a, b) => new Date(a.date) - new Date(b.date)).filter((j) => 
                    j.state === 1 &&
                    ((format(shiftDate, "yyyy-MM-dd") === j.date && convertShift() === "A" && j.shift === "I") ||
                        (format(shiftDate, "yyyy-MM-dd") === j.jobSplit.date && convertShift() === "A" && j.jobSplit.shift === "I") ||
                        (isAfter(new Date(j.date), shiftDate)) ||
                        (isAfter(new Date(j.jobSplit.date), shiftDate)))
                )

                newFutureJobs.push(...fJobs);

                //Going through jobs separately since futurejobs need different handling
                /*
                readyJobs.forEach((value, key) => {
                    if (value.draft 
                            && !Array.isArray(value.draft) 
                            && value.draft['>deletedSent'] === true
                        ) {
                        deletedJobs.push(value)
                    }
                })

                fJobs.forEach((value, key) => {
                    if (value.draft
                            && !Array.isArray(value.draft)
                            && value.draft['>deletedSent'] === true
                        ) {
                        deletedJobs.push(value)
                    }

                    if (value.state === 1) {
                        newFutureJobs.push(value)
                    }
                })

                */
                if (readyJobs.length < 1) {

                    nJobs = undefined

                } else {

                    readyJobs.filter(j => j.state === 1).forEach(e => {
                        const nuj = uj.find(y => y.id === e.id);
                        if (!nuj) {
                            uj.push(e);
                        }
                    })

                    const tempJobs = []

                    readyJobs.forEach((value, key) => {

                        if (!!shiftProgress.startTime && !value.user) {
                            tempJobs.push({ id: value.id, user: LogiTarUser.current.info.id })
                        }
                    })

                    //only one job can be split for a shift
                    const splitJob = readyJobs.find(e =>
                        e.jobSplit.id &&
                        e.jobSplit.date === format(shiftDate, "yyyy-MM-dd") &&
                        e.jobSplit.shift === convertShift()
                    );

                    if (splitJob) {

                        readyJobs = [...[splitJob], ...readyJobs.filter((e) => e.id !== splitJob.id)]

                        if (splitJob.continuedBy !== null) {
                            LogitarApi.setJobSplit({ id: splitJob.jobSplit.id, continuedBy: LogiTarUser.current.info.id }).then((result) => {
                            })
                        }
                    }

                    nJobs = readyJobs

                    if (tempJobs.length > 0) updateJobs(tempJobs);
                }

                if (planNotifs.length > 0 && !planNotifs.every(id => planInfosNotif.includes(id))) {
                    PlaySound("ring")
                }
                setPlanInfoNotifs(prev => ([...prev, ...planNotifs]))
                setPlanNotifJobs(prev => ([...prev, ...planNotifJobs]))
            }
        }

        if (nJobs) {
            // Initialize waitTime 0
            nJobs = nJobs.map(job => ({
                ...job,
                waitTime: (job.item.reqWaitTime == 1 && job.waitTime == null) ? 0 : job.waitTime
            }));
            // Initialize talenomVariant
            nJobs = initializeTalenomVariants(nJobs);
        }

        setJobs(nJobs)
        // setPendingFields({}); // Do more testing to see if needed
        setUnseenJobs([...uj]);
        // Filter handled events from events
        // use reference instead of state, since state might not be up to date
        eventsRef.current = eventsRef.current.filter((e) => !handledEvents.includes(e.id));
        setEvents([...eventsRef.current]);
        setDeletedJob(prev => [...prev, ...deletedJobs])
        setFutureJobs(newFutureJobs);

    }, [events]);

    useEffect(() => {
        if (!vehicleNumber)
            return;

        // Find vehicle from vehicles
        const vehicle = vehicles.find(v => v.id === vehicleNumber);

        LogitarApi.getServiceMonitor((vehicle && vehicle.trailer) ? [vehicleNumber, vehicle.trailer] : [vehicleNumber])
            .then((resp) => {
                if (resp.status) {
                    /** @type {import("../components/ServiceMonitorCard").ServiceMonitorVehicle} */
                    let mon = null;
                    /** @type {import("../components/ServiceMonitorCard").ServiceMonitorVehicle} */
                    let tMon = null;
                    resp.monitors.forEach((e) => {
                        if (e.vehicle.id === vehicleNumber) {
                            if (!mon) {
                                mon = {
                                    vehicle: e.vehicle,
                                    monitors: []
                                };
                            }
                            const m = { ...e };
                            delete m.vehicle;
                            mon.monitors.push(m);
                        }
                        else if (e.vehicle.id === vehicle.trailer) {
                            if (!tMon) {
                                tMon = {
                                    vehicle: e.vehicle,
                                    monitors: []
                                };
                            }
                            const m = { ...e };
                            delete m.vehicle;
                            tMon.monitors.push(m);
                        }
                    })

                    setVehicleMonitor(mon);
                    setVehicleMonitorTrailer(tMon);
                }

            })
            .catch((err) => {

            })
            .finally(() => {

            })
    }, [vehicleNumber, refreshMonitors])

    useEffect(() => {

        //Get all the news (tiedotteet)
        LogitarApi.getNews({ extent: "all", notification: true, user: LogiTarUser.current.token.user }).then((result) => {
            setRows(result.news)
            setNewsPopup(true)
        })
            .catch((err) => {
                setRows([])
                setNewsPopup(false)
                console.error(err)
            })

    }, [searchTimeStamp])


    useEffect(() => {
        if (unseenMessagesPopup && chains.length > 0)
            PlaySound("ring", true);
        else
            StopSound("ring");
    }, [unseenMessagesPopup, chains]);

    const getUnreadMessages = () => {

        //Fetch vehicles unread messages count
        let method = "unreadmessagesid"

        LogitarApi.getUnreadMessages(vehicleNumber, method, null).then((result) => {

            if (result.latestid !== null && result.latestid !== 0 && result.latestid != prevLatestId) {
                setPrevLatestId(result.latestid)

                //Fetch vehicles unread messages 
                method = "unreadmessages"
                LogitarApi.getUnreadMessages(vehicleNumber, method, null).then((result) => {
                    if (!result.chains.find(chain => chain.id === messageChainOpen)) {
                        handleMessagesPopup(false);
                        setUnseenChains([]);
                        setChains(result.chains);
                        if (!unseenMessagesPopup) setUnseenMessagesPopup(true);
                    } else {
                        setUnseenChains(result.chains);
                        setChains([]);
                    }
                })
                    .catch((err) => {
                        setUnseenChains([])
                        setChains([])
                        console.error(err)
                    }).finally(() => setMessagesWaitToAccept(prev => prev + 1))
            }
        })
            .catch((err) => {
                console.error(err)
            })
    }

    const setNewSearchTimeStamp = () => {
        setNewsPopup(false)
        setSearchTimeStamp(Date.now());
    };

    const handleMessagesPopup = (boolvalue) => {
        //alert("Näytetäänkö viestit" + boolvalue)
        setMessagesPopup(boolvalue)
        if (!boolvalue) setMessageChainOpen(null);
    };

    //To check if fields disabled or not
    const convertChecked = boolString => {
        return Boolean(Number(boolString));
    }

    const acceptDeletions = () => {

        const sendJob = deletedJob.map((job) => {
            return { id: job.id, vehicle: job.vehicle, ">forceDelete": true }
        })
        
        LogitarApi.setJobs(sendJob).then((result) => {
            setDeletedJob([])
        })
            .catch((err) => console.error(err))
    }

    const jobSeen = (jobs, future = false) => {

        let seenJobs = []

        let j = jobs.filter(e => e.state === 1)

        if (j.length > 0) {
            j.forEach((value, key) => {
                seenJobs.push({ id: value.id, state: 2 })
            })

            if (seenJobs.length < 1) {
                return
            }

            console.log("Jobs seen", seenJobs)

            LogitarApi.setJobs(seenJobs).then((result) => {
            })
                .catch((err) => console.error(err))
        }

        if (!future) setUnseenJobs([]);
    }

    //Load button function
    const jobLoaded = (jobId) => {

        const loadDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss')

        const sendJob = {
            id: jobId,
            loadTime: loadDate,
            state: 3,
            user: LogiTarUser.current.info.id,
            ">forceUpdate": true
        }

        const job = jobs.find(e => e.id === jobId)

        if (!job) return

        //Check if any of the sets are set selected in the item, then set them to 0
        Object.keys(sets).forEach((value) => {
            if (job.item[value]) {
                sendJob[sets[value].inJob] = 0
            }
        })

        //Send load datetime
        LogitarApi.setJobs([sendJob]).then((result) => {
            setCount(count + 1)
        })
            .catch((err) => console.error(err))
    }

    //Unload button function
    const jobUnLoaded = (job) => {

        if (job.state < 3) {
            return
        }

        const unloadDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss')

        const billingDate = format(LogiTarUser.current.info.userType === LogiTarUserType.CONTRACTOR ? new Date(job.date) : new Date(), 'yyyy-MM-dd');

        //Send unload datetime
        LogitarApi.setJobs([{ id: job.id, billingDate: billingDate, unloadTime: unloadDate, state: 4, ">forceUpdate": true }]).then((result) => {
            setCount(count + 1)
        })
            .catch((err) => console.error(err))
    }

    const numericalFields = ["tons", "tonsTare", "tonsGross", "m3", "count", "hours", "waitTime", "kilometres"]

    //Jobs field handle
    const handleJobInfo = (event, job, update = false) => {

        //Job to be updated
        let updatedJob

        const newData = jobs.map((njob, key) => {

            if (job.id == njob.id) {
                let v = event.target.value;
                if (numericalFields.includes(event.target.name)) {
                    v = v.replace(",", ".");
                }
                const isFishJob = Object.keys(fishRequires).some(k => fishRequires[k].inJob === event.target.name);
                if (isFishJob) {
                    updatedJob = { id: job.id, jobFish: { id: job.jobFish.id, [event.target.name]: v } }
                } else {
                    updatedJob = { id: job.id, [event.target.name]: v }
                }

                // Initialize waitTime 0
                updatedJob = { ...updatedJob, "waitTime": (job.item.reqWaitTime == 1 && job.waitTime == null) ? 0 : job.waitTime }
                // Initialize talenomVariant (alternative form of the function for this)
                updatedJob = initializeTalenomVariants([updatedJob], [job])[0];

                if (event.target.name === "tonsTare") {
                    updatedJob = { ...updatedJob, "tons": String((Number(job.tonsGross) - Number(v)).toFixed(3)) };
                }
                else if (event.target.name === "tonsGross") {
                    updatedJob = { ...updatedJob, "tons": String((Number(v) - Number(job.tonsTare)).toFixed(3)) };
                }
                if (isFishJob) {
                    if (event.target.name === "fishStatus") { // Don't replace the fishStatus array
                        return { ...jobs[key], jobFish: { ...jobs[key].jobFish, fishStatus: jobs[key].jobFish.fishStatus } }
                    }
                    return { ...jobs[key], jobFish: { ...jobs[key].jobFish, [event.target.name]: v } }
                }
                return { ...jobs[key], [event.target.name]: v }
            }

            return { ...jobs[key] }

        })

        setJobs(newData)

        if (update)
            jobUpdate([{ ...updatedJob, ">forceUpdate": true }], event.target.name)
    }

    //Callback so the jobs info gets updated in one go
    const jobUpdate = (job, triggerField) => {

        //console.log("Updating job...", job)
        const jobId = job[0].id;

        setPendingFields(prev => {
            if (prev[jobId] instanceof Set) {
                return ({ ...prev, [jobId]: prev[jobId].add(triggerField) });
            } else return ({ ...prev, [jobId]: new Set([triggerField]) });
        })

        LogitarApi.setJobs(job).then((result) => {
            setFailedFields(prev => {
                if (prev[jobId] instanceof Set) {
                    const newSet = new Set(prev[jobId]);
                    newSet.delete(triggerField);
                    return ({ ...prev, [jobId]: newSet });
                } else return ({ ...prev, [jobId]: new Set() });
            })
        })
            .catch((err) => {
                console.error(err)
                Object.keys(job[0]).forEach((key) => {
                    if (requireNames[key] && !!job[0][key] && key === triggerField) {
                        enqueueSnackbar(`Tarkista kenttä "${requireNames[key]}"`, { variant: 'warning' });
                    }
                    if (key === "jobFish") {
                        Object.keys(job[0].jobFish).forEach((fkey) => {
                            if (fishRequireNames[fkey] && !!job[0].jobFish[fkey] && fkey === triggerField) {
                                enqueueSnackbar(`Tarkista kenttä "${fishRequireNames[fkey]}"`, { variant: 'warning' });
                            }
                        })
                    }
                });
                setFailedFields(prev => {
                    if (prev[jobId] instanceof Set) {
                        return ({ ...prev, [jobId]: prev[jobId].add(triggerField) });
                    } else return ({ ...prev, [jobId]: new Set([triggerField]) });
                })
            })
            .finally(() => {
                setPendingFields(prev => {
                    if (prev[jobId] instanceof Set) {
                        const newSet = new Set(prev[jobId]);
                        newSet.delete(triggerField);
                        return ({ ...prev, [jobId]: newSet });
                    } else return ({ ...prev, [jobId]: new Set() });
                })
            })

    }

    const fuelUpdate = (force = false) => {
        if (fuelInfo.fuel) {
            fuelInfo.fuel = fuelInfo.fuel.replace(",", ".");
        }

        const fuelChanged = fuelInfo.fuel !== lastSentFuelInfo.fuel;
        const kilometresChanged = fuelInfo.kilometres !== lastSentFuelInfo.kilometres;

        const fuelDiff = fuelInfo.fuel ? Number(fuelInfo.fuel) - Number(prevFuelInfo.fuel) : 0;
        const kilometresDiff = fuelInfo.kilometres ? Number(fuelInfo.kilometres) - Number(prevFuelInfo.kilometres) : 0;

        // No change
        if (!fuelChanged && !kilometresChanged)
            return;

        console.log(lastSentFuelInfo.fuel);
        if (fuelChanged && !force) {
            // Check if fuel has been set already
            if (!!lastSentFuelInfo.fuel && lastSentFuelInfo.fuel != "" && lastSentFuelInfo.fuel != "null") {
                setFuelWarningText("Olet muuttamassa jo tallennettua tietoa käyttövoiman määrästä");
                return;
            }
        }

        if (kilometresChanged && !force) {
            // Check distance limits
            if (kilometresDiff < 200) {
                setFuelWarningText("Olet tallentamassa alle 200km matkaa");
                return;
            }
            else if (kilometresDiff > 800) {
                setFuelWarningText("Olet tallentamassa yli 800km matkaa");
                return;
            }
            else if (kilometresDiff == 0) {
                setFuelWarningText("Olet tallentamassa 0km");
                return;
            }
            // Check if kilometres has been set already
            if (!!lastSentFuelInfo.kilometres && lastSentFuelInfo.kilometres != "" && lastSentFuelInfo.kilometres != "null") {
                setFuelWarningText("Olet muuttamassa jo tallennettua tietoa kilometreistä");
                return;
            }
        }

        if ((fuelInfo.kilometres && Number(prevFuelInfo.kilometres) > Number(fuelInfo.kilometres)) && !force)
            return;

        let fuelType = FuelType.diesel;
        const v = vehicles.find(e => e.id === vehicleNumber);
        if (v) {
            fuelType = v.fuelType;
        }

        const params = {
            user: LogiTarUser.current.info.id,
            vehicle: vehicleNumber,
            fuel: fuelInfo.fuel || null,
            kilometres: fuelInfo.kilometres || null,
            shift: convertShift(),
            dateTime: format(new Date(shiftDate), "yyyy-MM-dd HH:mm:ss"),
            fuelType: fuelInfo.type || fuelType,
        }

        setLastSentFuelInfo({
            fuel: fuelInfo.fuel,
            kilometres: fuelInfo.kilometres,
            type: fuelInfo.type,
        });

        LogitarApi.setFuel(params)
            .then((t) => {
            })
            .catch((err) => {
                console.error(err);
            })
    }


    //"Lähetä" button func
    //Check each job for checkup, then set billing field if valids found
    const jobsToCheckup = () => {

        const validJobs = []
        if (!jobs)
            return;

        jobs.forEach((job, i) => {
            if (
                (job.checkState > 1 || job.unloadTime === "") ||
                (job.jobSplit.id && !(job.jobSplit.date === format(shiftDate, "yyyy-MM-dd") && job.jobSplit.shift === convertShift()))
            ) {
                //If job is not split for this shift, then don't take it into account
            }
            else {
                let item = job.item
                let checkState = 2

                Object.keys(requires).forEach((require) => {
                    if (item[require] && job[requires[require].inJob] === "") {
                        checkState = 1
                        return
                    }
                })

                validJobs.push({ id: job.id, checkState: checkState })
            }
        })

        if (validJobs.length < 1) {
            return
        }

        LogitarApi.setJobs(validJobs).then((result) => {
            if (result.status) {
                setJobsSent(true)
                setCount(prev => prev + 1)
            }
        })
            .catch((err) => { console.log(err) })

    }

    const shiftHandle = (name, force = false) => {

        setShiftStarting(prev => ({ ...prev, [name]: true }))

        const time = format(new Date(), "yyyy-MM-dd HH:mm:ss")

        let wt = {
            [name]: time
        }

        let info = "aloitettu"


        if (name === "startTime") {
            wt = {
                ...wt,
                endTime: null,
                date: format(shiftDate, "yyyy-MM-dd"),
                costCentre: LogiTarUser.current.vehicle.costCentre,
                shift: convertShift(),
                workHours: 0.0,
                eveningHours: 0.0,
                nightHours: 0.0,
                breakHours: 0.0,
                waitHours: 0.0,
                shortTime: 0.0,
                sickHours: 0.0,
                vacationHours: 0.0,
                holidayHours: 0.0,
                paternityLeaveHours: 0.0,
                sundayHours: 0.0,
                dailyAllowance: 0,
                route: "",
                user: LogiTarUser.current.info.id,
                vehicle: LogiTarUser.current.vehicle.id
            }

            LogitarApi.setWorkHours([wt]).then((r) => {

                const lID = r["latestID"]

                setShiftProgress({ ...shiftProgress, [name]: time, id: lID })

                enqueueSnackbar(`Työvuoro ${info}`)

                LogitarApi.setUser({ id: LogiTarUser.current.info.id, currentShift: lID }).catch(err => console.error(err));

                LogiTarUser.current.info.currentShift = lID;

                const tempJobs = []

                jobs.forEach((value, key) => {

                    if (!value.user) {
                        tempJobs.push({ id: value.id, user: LogiTarUser.current.info.id })
                    }
                })

                setShiftStarting(prev => ({ ...prev, [name]: false }))

                if (tempJobs.length > 0) updateJobs(tempJobs);

            }).catch((e) => {
                console.error(e)

                const timeout = 5000;

                if (e.error === 69) {
                    enqueueSnackbar(`Vuoron aloitus on jo voimassa kalustolla ja vuorolla. Päivitetään ruutu ${timeout / 1000} sekunnin päästä...`, { variant: "error" })
                    setTimeout(() => {
                        window.location.reload()
                    }, [5000])
                }

                setShiftStarting(prev => ({ ...prev, [name]: false }))
            })

            LogitarApi.setVehicle({ id: vehicleNumber, currentUser: LogiTarUser.current.info.id }).catch(err => console.error(err));
        }


        if (name === "endTime") {


            if (!jobsSent && !force) {
                setTryingToEnd(true)
                setShiftStarting(prev => ({ ...prev, [name]: false }))
                return
            }

            if (shiftProgress.startTime === null) {
                enqueueSnackbar("Vuoroa ei aloitettu", { variant: "error" })
                setShiftStarting(prev => ({ ...prev, [name]: false }))
                return
            }

            if (!shiftProgress.id) {
                console.log("Vuoroa ei pystytty lopettamaan", shiftProgress)
                setShiftStarting(prev => ({ ...prev, [name]: false }))
                return
            }

            calculateWorkHours({
                start: new Date(shiftProgress.startTime),
                end: new Date(wt.endTime)
            }).then((calculatedHours) => {                

                let breakHours = calculatedHours.workHours < 8 ? 0.0 : 0.5;

                if (Config.getBranch() === "konnekuljetus")
                    breakHours = 0.0;

                wt = {
                    ...wt,
                    ...calculatedHours,
                    id: shiftProgress.id,
                    breakHours: breakHours
                }

                info = "lopetettu"

                LogitarApi.setWorkHours([wt]).then((r) => {

                    setShiftProgress({ ...shiftProgress, [name]: time })

                    enqueueSnackbar(`Työvuoro ${info}`);

                    setShiftStarting(prev => ({ ...prev, [name]: false }))

                    //Compare only to Driver since Contractors don't have to fill workhours
                    if (LogiTarUser.current.info.userType === LogiTarUserType.DRIVER) {
                        setEndedShift(true)
                    }

                    // Null the currentUser for the vehicle when ending shift, if you are the currentUser
                    LogitarApi.getVehicles({ id: vehicleNumber, extent: "all" }).then(res => {
                        if (res.vehicles[0].currentUser === LogiTarUser.current.info.id) {
                            LogitarApi.setVehicle({ id: vehicleNumber, currentUser: null }).catch(err => console.log(err));
                        }
                    }).catch(err => {
                        console.error(err)
                    });

                    LogitarApi.setUser({ id: LogiTarUser.current.info.id, currentShift: null }).catch(err => console.error(err));

                    LogiTarUser.current.info.currentShift = null;

                }).catch((e) => {
                    console.error(e)
                    setShiftStarting(prev => ({ ...prev, [name]: false }))
                });

            }).catch((e) => {
                console.error(e)
                setShiftStarting(prev => ({ ...prev, [name]: false }))
            });

            setTryingToEnd(false);
        }
    }

    const handleAcceptChain = () => {
        setUnseenChains([])
        setChains([])
        handleMessagesPopup(false)
        setMessageChainOpen(null);
    };

    if (proceed) {
        let fuelTypeLabel = "Polttoaine";
        let fuelType = FuelType.diesel;
        let fuelUnit = "";
        const v = vehicles.find(e => e.id === vehicleNumber);
        if (v) {
            const ft = fuelTypeList.find(e => e.value === (fuelInfo.type || v.fuelType));
            if (ft) {
                fuelType = ft.value;
                fuelTypeLabel = ft.label;
                fuelUnit = ft.unit;
            }
        }

        return (

            <Stack

                spacing={{ xs: 2, sm: 1 }}
                direction="column"
                useFlexGap
                flexWrap="wrap"
                sx={{ overflowX: 'auto' }}
            >
                <ErrorBanner errorTypes={['browserOffline', 'apiCallFailed']} showRefreshButton />

                <Box sx={{ display: 'flex', flexDirection: smBreakPoint ? 'row' : 'column', ml: 1, maxWidth: 'max-content', overflowX: 'auto', columnGap: 2 }}>
                    <Box sx={{ ...shiftScreenStyle, flex: 1, ...(theme.palette.mode === 'dark' && shiftScreenDarkStyleOverrides) }}>
                        <Box sx={toolStyle}>
                            {
                                LogiTarUser.current.info.userType === LogiTarUserType.DRIVER &&
                                <Button
                                    disabled={shiftProgress.startTime !== null || !!shiftLock || shiftStarting.startTime}
                                    variant="contained"
                                    onClick={() => setShiftActionPop({ open: true, mode: "startTime" })}
                                >
                                    {
                                        shiftStarting.startTime ?
                                            <Box sx={{ width: "101px", pt: 1 }}>
                                                <CircularProgress size={25}></CircularProgress>
                                            </Box>
                                            : "Aloita Vuoro"
                                    }
                                </Button>
                            }
                            <Box sx={{ flexDirection: "row", display: "inline-flex", gap: 1, alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
                                <FormGroup row sx={{ gap: 1, alignItems: "center", justifyContent: "center" }}>
                                    <Button variant="contained" onClick={() => handleButton(-1)}><ArrowBack /></Button>
                                    <LocalizationProvider sx={{ height: "200px" }} adapterLocale={fi} dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            inputFormat='dd.MM.yyyy'
                                            mask={"__.__.____"}
                                            value={shiftDate}
                                            onChange={(value) => { setShiftDate(value) }}
                                            renderInput={(params) => <TextField size="small" sx={{ mx: 1 }} {...params} />}
                                            sx={{ width: 150 }}
                                        ></DesktopDatePicker>
                                    </LocalizationProvider>
                                    <Button variant="contained" onClick={() => handleButton(1)}><ArrowForward /></Button>
                                </FormGroup>
                                <FormGroup row sx={{ alignItems: "center" }}>
                                    <Typography>Aamu</Typography>
                                    <Switch checked={shift} onChange={() => setShift(!shift)} />
                                    <Typography>Ilta</Typography>
                                </FormGroup>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                <Tooltip title={allowSendTooltips.length > 0 ? <SendButtonTooltipContent /> : ""}>
                                    <span>

                                        <Button
                                            variant="contained"
                                            onClick={jobsToCheckup}
                                            disabled={(allowSendTooltips.length > 0) || (getUnCheckedJobsCount() == 0)}
                                        >
                                            Lähetä
                                        </Button>
                                    </span>
                                </Tooltip>
                                {
                                    LogiTarUser.current.info.userType === LogiTarUserType.DRIVER &&
                                    <Button disabled={shiftProgress.endTime !== null || shiftProgress.startTime === null || shiftLock || shiftStarting.endTime}
                                        variant="contained" onClick={() => setShiftActionPop({ open: true, mode: "endTime" })}
                                    >
                                        {
                                            shiftStarting.endTime ?
                                                <Box sx={{ width: "105px", pt: 1 }}>
                                                    <CircularProgress size={25}></CircularProgress>
                                                </Box>
                                                : "Lopeta Vuoro"
                                        }
                                    </Button>
                                }
                            </Box>

                        </Box>
                        <Box sx={{
                            ...toolStyle, '& .MuiFormControl-root ': {
                                // mr: 1,
                                width: 130
                            },
                            justifyContent: "space-between",
                        }}>
                            {
                                LogiTarUser.current.info.userType === LogiTarUserType.DRIVER &&
                                <>
                                    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 1 }}>
                                        <TextField
                                            label="Km" size="small" value={fuelInfo.kilometres} type={"tel"} step={1}
                                            onChange={(e) => setFuelInfo({ ...fuelInfo, ...{ kilometres: e.target.value } })}
                                            placeholder={prevFuelInfo.kilometres ? prevFuelInfo.kilometres : '0'}
                                            error={prevFuelInfo.kilometres && (Number(prevFuelInfo.kilometres) > Number(fuelInfo.kilometres))}
                                            helperText={prevFuelInfo.kilometres && (Number(prevFuelInfo.kilometres) > Number(fuelInfo.kilometres)) ?
                                                `min. ${prevFuelInfo.kilometres} km` : ""}
                                            onBlur={(e) => fuelUpdate()}
                                        />
                                        <TextField
                                            label={fuelTypeLabel} size="small" value={fuelInfo.fuel} type={"tel"} step={0.1}
                                            onChange={(e) => setFuelInfo({ ...fuelInfo, ...{ fuel: e.target.value } })}
                                            onBlur={(e) => fuelUpdate()}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment sx={{ "& .MuiTypography-root": { fontSize: "0.9rem", lineHeight: "unset" } }} position='end'>{fuelUnit}</InputAdornment>
                                            }}
                                        />
                                        {vehicleNumber && vehicles.find(v => v.id === vehicleNumber) &&
                                            vehicles.find(v => v.id === vehicleNumber).fuelType === FuelType.biodiesel &&
                                            <Select
                                                sx={{ width: 113, height: 40 }} size='small' value={fuelInfo.type ? fuelInfo.type : FuelType.biodiesel}
                                                onChange={(e) => setFuelInfo(prev => ({ ...prev, type: e.target.value }))}
                                                onBlur={() => fuelUpdate()}
                                            >
                                                <MenuItem value={FuelType.biodiesel}>Biodiesel</MenuItem>
                                                <MenuItem value={FuelType.diesel}>Diesel</MenuItem>
                                            </Select>
                                        }
                                    </Box>

                                    <Box sx={{ flexDirection: 'column', display: 'flex', p: 0, fontSize: '0.8em', flex: 1 }}>
                                        {
                                            // Distance travelled
                                            (prevFuelInfo.kilometres && (Number(fuelInfo.kilometres) > Number(prevFuelInfo.kilometres))) &&
                                            <Box sx={{ flex: 1, m: 0, p: 0 }}>
                                                {(Number(fuelInfo.kilometres) - Number(prevFuelInfo.kilometres)).toFixed(0)}km
                                            </Box>
                                        }
                                        {
                                            // Average consumption
                                            (fuelInfo.fuel > 0 && prevFuelInfo.kilometres && (Number(fuelInfo.kilometres) > Number(prevFuelInfo.kilometres))) &&
                                            <Box sx={{ flex: 1, m: 0, p: 0 }}>
                                                {(Number(fuelInfo.fuel) / (Number(fuelInfo.kilometres) - Number(prevFuelInfo.kilometres)) * 100).toFixed(1)}{fuelUnit} / 100km
                                            </Box>
                                        }
                                    </Box>
                                </>
                            }


                            <Box sx={{ flexDirection: 'column', display: 'flex', p: 0, pr: 2, fontSize: '0.8em', flex: 1 }}>
                                <Stack>
                                    <div style={{ textAlign: "right" }}>
                                        Seuraavan vuoron kuljettaja:
                                    </div>
                                    {
                                        nextDriver.current ?
                                            <div style={{ textAlign: "right" }}>
                                                {nextDriver.current.user} {nextDriver.current.nextDriver}
                                            </div>
                                            : <div style={{ textAlign: "right" }}>
                                                Ei kuljettajaa
                                            </div>
                                    }
                                </Stack>
                            </Box>
                            {
                                // Maybe not needed?
                                //<TextField label="AdBlue" size="small"></TextField>
                            }
                        </Box>


                        {/**Jobs box*/}
                        <Box sx={
                            {
                                ...shiftScreenStyle,
                                // pb: 2,
                                // mr: 2,
                                // mb: 2,
                                mt: 0,
                                '& .MuiFormControl-root ': {
                                    mr: 3,
                                    width: 100,
                                    mb: 2
                                }, '& .MuiTypography-root ': {
                                    mr: 1,
                                    p: 0

                                },
                                ...(theme.palette.mode === 'dark' && shiftScreenDarkStyleOverrides),
                            }}>

                            {loading ? <Loader></Loader> :
                                jobs ? jobs.map((job, key) => {

                                    if (job.state === 0) {
                                        return null
                                    }

                                    //Determine jobsplit state, false if not jobsplit id set (not split), left is split from this shift, right is split from some previous shift
                                    const splitState = job.jobSplit?.id ?
                                        (job.jobSplit.date === format(shiftDate, "yyyy-MM-dd") &&
                                            job.jobSplit.shift === convertShift()) ? "right" : "left"
                                        : false

                                    //Find plans item info
                                    let item = job.item

                                    return (
                                        <Box key={key} sx={{ borderRadius: 1.5, mb: 1, pb: 1 }} className={(key % 2 === 0) ? "default-" : "default-grey"}>


                                            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                                                <Typography sx={{ fontSize: "1.4rem", fontWeight: "bold", display: "flex", flexDirection: "row", gap: 2, alignItems: "center" }}>
                                                    {`${splitState === "right" ? 1 : job.slot + 1} - ${item.name} - ${item.cargoLabel}`}
                                                    {item.itemType === "fish" && <FishIcon />}
                                                </Typography>

                                                <Box sx={{ mr: 2, display: "flex", flexDirection: "column", alignItems: "end" }}>

                                                    {splitState === "left" &&
                                                        <Typography style={{ color: (theme.palette.mode === 'light') ? "blue" : "lightblue", fontWeight: "bold", fontSize: "1.0rem" }}>HAKU VAIHTOON</Typography>
                                                    }

                                                    {splitState === "right" &&
                                                        <Typography style={{ color: (theme.palette.mode === 'light') ? "blue" : "lightblue", fontWeight: "bold", fontSize: "1.0rem" }}>VIENTI VAIHDOSTA</Typography>
                                                    }

                                                    {
                                                        job.interClient &&
                                                        <img src={logoPathsSmall[job.interClient].path} style={{ height: 24, marginRight: 10 }} />
                                                    }

                                                    {job.checkState >= 2 &&
                                                        // <Check color='success' />
                                                        <Typography style={{ color: "green", fontWeight: "bold", fontSize: "1.2rem" }}>Lähetetty</Typography>
                                                    }


                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "row", mx: 1, width: "auto", justifyContent: "space-between" }}>



                                                <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 1 }}>
                                                    <Tooltip arrow title={!shiftProgress.startTime ? "Vuoroa ei ole aloitettu" : null}>
                                                        <Box sx={{ mr: 1 }}>
                                                            <Button
                                                                disabled={!(job.state < 3) || splitState === "right" || (!shiftProgress.startTime && !LogiTarUser.current.isContractor())}
                                                                variant="contained"
                                                                onClick={() => jobLoaded(job.id)}
                                                            >
                                                                Aloita
                                                            </Button>
                                                        </Box>
                                                    </Tooltip>

                                                    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                                        {
                                                            splitState !== false ?
                                                                <Tooltip sx={{ fontSize: 24 }} title={<h2>{job.jobSplit.switchLocation}</h2>}>
                                                                    <CallSplit sx={{ transform: "rotate(90deg)", ...iconStyle, }} ></CallSplit>
                                                                </Tooltip>
                                                                : <div style={{ width: 24, marginLeft: 8, marginRight: 8 }}></div>
                                                        }

                                                        {job.planDetails ?
                                                            <div>
                                                                <div className={planInfosNotif.includes(job.id) ? 'default-red' : 'default-green'} style={{ borderRadius: 4, animation: planInfosNotif.includes(job.id) ? 'blinker 4s linear infinite' : "" }}>
                                                                    <DescriptionIcon onClick={(e) => handleInfoClick(e, job.id)} sx={{ ...iconStyle, }} />
                                                                </div>
                                                                <Popover
                                                                    sx={{ ml: 3, borderRadius: 2 }}
                                                                    open={anchorEl.popoverId === job.id}
                                                                    anchorEl={anchorEl.anchorEl}
                                                                    transformOrigin={key > 1 ? { vertical: "bottom", horizontal: "left" } : { vertical: 'top', horizontal: 'left' }}
                                                                    anchorReference="anchorEl"
                                                                    onClose={handleInfoClose}
                                                                >
                                                                    <Box sx={{ borderRadius: 2, p: 1, maxWidth: 500 }} className="default-grey">
                                                                        <Paper elevation={5} sx={{ display: "block", width: "auto", p: 1, maxHeight: "50vh", overflowY: "auto" }}>
                                                                            <Typography variant="h6">Lisätiedot kuljettajalle</Typography>
                                                                            <Divider></Divider>
                                                                            <Typography sx={{ whiteSpace: "pre-line" }}>{job.planDetails}</Typography>
                                                                        </Paper>
                                                                    </Box>
                                                                </Popover>
                                                            </div>
                                                            : <Box sx={{ width: "40px" }}></Box>
                                                        }

                                                        {item.notes ?
                                                            <div>
                                                                <NotesIcon sx={iconStyle} onClick={(e) => handleInfoClickItem(e, job.id)} />
                                                                <Popover
                                                                    sx={{ ml: 4, borderRadius: 2, mt: 4, maxHeight: '50vh', }}
                                                                    open={anchorElItem.popoverId === job.id}
                                                                    disablePortal
                                                                    anchorEl={anchorElItem.anchorEl}
                                                                    anchorReference="anchorEl"
                                                                    transformOrigin={key > 1 ? { vertical: "bottom", horizontal: "left" } : { vertical: 'top', horizontal: 'left' }}
                                                                    onClose={handleInfoCloseItem}
                                                                >
                                                                    <Box sx={{ borderRadius: 2, p: 1 }} className="default-grey">
                                                                        <Paper elevation={5} sx={{ display: "block", p: 1, maxHeight: "auto", overflowY: "auto", }}>
                                                                            <Typography variant="h6">Nimikkeen ohjeet</Typography>
                                                                            <Divider></Divider>
                                                                            <Typography sx={{ whiteSpace: "pre-line", mt: 1 }}>{item.notes}</Typography>
                                                                        </Paper>

                                                                        {(itemsFiles && itemsFiles.length > 0) &&
                                                                            <Box sx={{ display: 'flex', flex: 1, width: '25vw', maxWidth: '30vw' }}>
                                                                                <FileManager
                                                                                    files={itemsFiles}
                                                                                    sx={{ flex: 1, my: 1 }}
                                                                                    disableEditing
                                                                                    title="Nimikkeen liitteet"
                                                                                />
                                                                            </Box>
                                                                        }
                                                                    </Box>

                                                                </Popover>
                                                            </div>
                                                            : <Box sx={{ width: "40px" }}></Box>
                                                        }

                                                        <div>
                                                            <MapIcon sx={iconStyle} onClick={(e) => handleInfoClickArea(e, job.id)}></MapIcon>
                                                            <Popover
                                                                sx={{ ml: 4, borderRadius: 2, mt: 4, maxHeight: '50vh' }}
                                                                open={anchorElArea.popoverId === job.id}
                                                                disablePortal
                                                                anchorEl={anchorElArea.anchorEl}
                                                                anchorReference="anchorEl"
                                                                transformOrigin={key > 1 ? { vertical: "bottom", horizontal: "left" } : { vertical: 'top', horizontal: 'left' }}
                                                                onClose={handleInfoCloseArea}>
                                                                <Box sx={{ borderRadius: 2, p: 1 }} className="default-grey">
                                                                    <Paper elevation={5} sx={{ display: "block", p: 1, maxHeight: "auto", overflowY: "auto", }}>
                                                                        <Typography variant="h6">Osoitetiedot ja alueiden ohjeet</Typography>
                                                                        <Table sx={{ "& .MuiTableCell-root": { fontSize: 16, padding: 1 } }}>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell>
                                                                                        Alueen nimi
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        Lastausalue ja Purkualue
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        Ohjeet
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        Koordinaatit
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell>{item.departure.name}</TableCell>
                                                                                    <TableCell>
                                                                                        {
                                                                                            (item.departure.coordinates && checkLatLng(item.departure.coordinates)) ?
                                                                                                <Link
                                                                                                    sx={{ cursor: "pointer" }}
                                                                                                    onClick={(e) => {
                                                                                                        const coords = item.departure.coordinates ?
                                                                                                            {
                                                                                                                lat: Number(item.departure.coordinates.split(",")[0]),
                                                                                                                lng: Number(item.departure.coordinates.split(",")[1])
                                                                                                            } :
                                                                                                            null;
                                                                                                        setJobDetailsSelectedJob({ focus: coords, job: job });
                                                                                                        setJobDetailsMapAnchor(e.currentTarget);
                                                                                                    }}
                                                                                                >
                                                                                                    {item.departure.address}
                                                                                                </Link>
                                                                                                : item.departure.coordinates
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell><Typography sx={{ whiteSpace: "pre-line" }}>{item.departure.details}</Typography></TableCell>
                                                                                    <TableCell>{item.departure.coordinates}</TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell>{item.arrival.name}</TableCell>
                                                                                    <TableCell>
                                                                                        {
                                                                                            (item.arrival.coordinates && checkLatLng(item.arrival.coordinates)) ?
                                                                                                <Link
                                                                                                    sx={{ cursor: "pointer" }}
                                                                                                    onClick={(e) => {
                                                                                                        const coords = item.arrival.coordinates ?
                                                                                                            {
                                                                                                                lat: Number(item.arrival.coordinates.split(",")[0]),
                                                                                                                lng: Number(item.arrival.coordinates.split(",")[1])
                                                                                                            } :
                                                                                                            null;
                                                                                                        setJobDetailsSelectedJob({ focus: coords, job: job });
                                                                                                        setJobDetailsMapAnchor(e.currentTarget);
                                                                                                    }}
                                                                                                >
                                                                                                    {item.arrival.address}
                                                                                                </Link>
                                                                                                : item.arrival.coordinates
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell><Typography sx={{ whiteSpace: "pre-line" }}>{item.arrival.details}</Typography></TableCell>
                                                                                    <TableCell>{item.arrival.coordinates}</TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </Paper>
                                                                    <Popover
                                                                        open={!!jobDetailsSelectedJob}
                                                                        anchorEl={jobDetailsMapAnchor}
                                                                        onClose={() => {
                                                                            setJobDetailsSelectedJob(null);
                                                                            setJobDetailsMapAnchor(null);
                                                                        }}
                                                                    >
                                                                        <MapViewer
                                                                            jobs={jobDetailsSelectedJob ? [jobDetailsSelectedJob.job] : []}
                                                                            style={{
                                                                                width: "33vw",
                                                                                height: "33vw",
                                                                            }}
                                                                            initialCenter={jobDetailsSelectedJob ? jobDetailsSelectedJob.focus : null}
                                                                            initialZoom={jobDetailsSelectedJob ? 14 : 6}
                                                                            enableRoutes
                                                                        />
                                                                    </Popover>
                                                                    {(departureFiles && departureFiles.length > 0) &&
                                                                        <Box sx={{ display: 'flex', flex: 1, maxWidth: '30vw' }}>
                                                                            <FileManager
                                                                                files={departureFiles}
                                                                                sx={{ flex: 1, my: 1 }}
                                                                                disableEditing
                                                                                title="Latausalueen liitteet"
                                                                            />
                                                                        </Box>
                                                                    }
                                                                    {(arrivalFiles && arrivalFiles.length > 0) &&
                                                                        <Box sx={{ display: 'flex', flex: 1, maxWidth: '30vw' }}>
                                                                            <FileManager
                                                                                files={arrivalFiles}
                                                                                sx={{ flex: 1, my: 0 }}
                                                                                disableEditing
                                                                                title="Purkualueen liitteet"
                                                                            />
                                                                        </Box>
                                                                    }

                                                                </Box>
                                                            </Popover>
                                                        </div>
                                                    </Box>
                                                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                                        {Object.keys(requires).slice(0, -1).map((field, key) => {
                                                            // Require talenomVariant if options exist in item
                                                            if (requires[field].inJob === "talenomVariant") {
                                                                const variants = item.talenomVariants;
                                                                if (!variants || variants.length < 1) return null;
                                                                return (
                                                                    <TextField
                                                                        key={key + " " + job.id}
                                                                        select
                                                                        name='talenomVariant'
                                                                        disabled={
                                                                            (splitState === "right" && job.checkState >= 2) ||
                                                                            ((!shiftProgress.startTime && !LogiTarUser.current.isContractor() && splitState !== "right") || job.checkState >= 2)
                                                                        }
                                                                        label={requires[field].label}
                                                                        size='small'
                                                                        style={{ width: "12rem" }}
                                                                        value={job[requires[field].inJob]}
                                                                        onChange={(event) => handleJobInfo(event, job)}
                                                                        onBlur={(event) => handleJobInfo(event, job, true)}
                                                                        SelectProps={{ SelectDisplayProps: { style: fieldFailedProps(failedFields[job.id], requires[field].inJob, theme).style } }}
                                                                        InputProps={fieldPendingProps(pendingFields[job.id], requires[field].inJob, { mr: 2.5 })}
                                                                        error={fieldFailedProps(failedFields[job.id], requires[field].inJob, theme).error}
                                                                    >
                                                                        {variants.map((v) => (
                                                                            <MenuItem value={v.product}>{v.name}</MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                )
                                                            }

                                                            if (item.itemType === "fish" && requires[field].fish && item.itemFish && convertChecked(item.itemFish[field]) && splitState !== "left") {
                                                                return <DriverFishFields
                                                                    key={key + " " + job.id}
                                                                    requires={requires}
                                                                    job={job}
                                                                    field={field}
                                                                    handleJobInfo={handleJobInfo}
                                                                    failedFields={failedFields[job.id]}
                                                                    fieldFailedProps={fieldFailedProps}
                                                                    pendingFields={pendingFields[job.id]}
                                                                    fieldPendingProps={fieldPendingProps}
                                                                    disabled={
                                                                        (splitState === "right" && job.checkState >= 2) ||
                                                                        ((!shiftProgress.startTime && !LogiTarUser.current.isContractor() && splitState !== "right") || job.checkState >= 2)
                                                                    }
                                                                />
                                                            }


                                                            if (!convertChecked(item[field]) || splitState === "left") {
                                                                return null
                                                            }

                                                            if (requires[field].inJob === "tons") {

                                                                //If reqTons is 2 in item, then show simple input (Net input)                                                                
                                                                if (item.reqTons === 2) {
                                                                    return (
                                                                        <TextField
                                                                            key={key + " " + job.id}
                                                                            disabled={
                                                                                (splitState === "right" && job.checkState >= 2) ||
                                                                                ((!shiftProgress.startTime && !LogiTarUser.current.isContractor() && splitState !== "right") || job.checkState >= 2)
                                                                            }
                                                                            name={requires[field].inJob}
                                                                            label={requires[field].label}
                                                                            value={job[requires[field].inJob]}
                                                                            onChange={(event) => handleJobInfo(event, job)}
                                                                            onBlur={(event) => handleJobInfo(event, job, true)}
                                                                            type='tel'
                                                                            size="small"
                                                                            className={requires[field].billingReason === item.billingReason ? "default-green" : ""}
                                                                            inputProps={{
                                                                                min: 0, step: requires[field].step || 1,
                                                                                style: fieldFailedProps(failedFields[job.id], requires[field].inJob, theme).style,
                                                                            }}
                                                                            InputProps={fieldPendingProps(pendingFields[job.id], requires[field].inJob)}
                                                                            error={fieldFailedProps(failedFields[job.id], requires[field].inJob, theme).error}
                                                                        />
                                                                    )
                                                                }

                                                                return (
                                                                    <>
                                                                        <TextField
                                                                            key={key + " " + job.id}
                                                                            disabled={
                                                                                (splitState === "right" && job.checkState >= 2) ||
                                                                                ((!shiftProgress.startTime && !LogiTarUser.current.isContractor() && splitState !== "right") || job.checkState >= 2)
                                                                            }
                                                                            name={requires[field].inJob}
                                                                            label={requires[field].label}
                                                                            value={job[requires[field].inJob]}
                                                                            onClick={(event) => handleOpenTonsDialog(event, job.id)}
                                                                            onKeyDown={(event) => { if (!(event.key === "Tab" && event.shiftKey) && event.key !== "Shift") handleOpenTonsDialog(event, job.id) }}
                                                                            type='tel'
                                                                            size="small"
                                                                            className={requires[field].billingReason === item.billingReason ? "default-green" : ""}
                                                                            inputProps={{
                                                                                min: 0, step: requires[field].step || 1, sx: { cursor: "pointer" },
                                                                                style: fieldFailedProps(failedFields[job.id], ["tonsTare", "tonsGross"], theme).style,
                                                                            }}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                                endAdornment: fieldPendingProps(pendingFields[job.id], ["tonsTare", "tonsGross"]).endAdornment,
                                                                            }}
                                                                            error={Number(job["tons"]) < 0 || fieldFailedProps(failedFields[job.id], ["tonsTare", "tonsGross"], theme).error}
                                                                        />
                                                                        {/* Tons popover */}
                                                                        <Popover
                                                                            open={openTonsInput === job.id}
                                                                            onClose={() => handleCloseTonsDialog()}
                                                                            anchorEl={tonsAnchorEl}
                                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                                                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                                                            <Box sx={{ m: 2, overflowY: "visible", display: "flex", flexDirection: "column", gap: 2 }}>
                                                                                <TextField
                                                                                    sx={{ width: "6rem" }}
                                                                                    size="small"
                                                                                    name="tonsTare"
                                                                                    label="Taara"
                                                                                    type='tel'
                                                                                    value={job.tonsTare}
                                                                                    onChange={(event) => handleJobInfo(event, job)}
                                                                                    onBlur={(event) => handleJobInfo(event, job, true)}
                                                                                    error={Number.isNaN(Number(job["tonsTare"])) || Number(job["tonsTare"]) > 999.999 || Number(job["tonsTare"]) > Number(job["tonsGross"])
                                                                                        || fieldFailedProps(failedFields[job.id], "tonsTare", theme).error}
                                                                                    onKeyDown={(event) => { if (event.key === "Enter" || event.key === "Tab" && event.shiftKey) handleCloseTonsDialog() }}
                                                                                    InputProps={fieldPendingProps(pendingFields[job.id], "tonsTare")}
                                                                                    inputProps={{ style: fieldFailedProps(failedFields[job.id], "tonsTare", theme).style }}
                                                                                />
                                                                                <TextField
                                                                                    sx={{ width: "6rem" }}
                                                                                    size="small"
                                                                                    name="tonsGross"
                                                                                    label="Brutto"
                                                                                    type='tel'
                                                                                    value={job.tonsGross}
                                                                                    onChange={(event) => handleJobInfo(event, job)}
                                                                                    onBlur={(event) => handleJobInfo(event, job, true)}
                                                                                    error={Number.isNaN(Number(job["tonsGross"])) || Number(job["tonsGross"]) > 999.999 || Number(job["tonsTare"]) > Number(job["tonsGross"])
                                                                                        || fieldFailedProps(failedFields[job.id], "tonsGross", theme).error}
                                                                                    onKeyDown={(event) => { if (event.key === "Enter" || event.key === "Tab" && !event.shiftKey) handleCloseTonsDialog() }}
                                                                                    InputProps={fieldPendingProps(pendingFields[job.id], "tonsGross")}
                                                                                    inputProps={{ style: fieldFailedProps(failedFields[job.id], "tonsGross", theme).style }}
                                                                                />
                                                                            </Box>
                                                                        </Popover>
                                                                    </>
                                                                )
                                                            }



                                                            return (

                                                                <TextField
                                                                    key={key + " " + job.id}
                                                                    disabled={
                                                                        (splitState === "right" && job.checkState >= 2) ||
                                                                        ((!shiftProgress.startTime && !LogiTarUser.current.isContractor() && splitState !== "right") || job.checkState >= 2)
                                                                    }
                                                                    name={requires[field].inJob}
                                                                    label={requires[field].label}
                                                                    value={job[requires[field].inJob]}
                                                                    onChange={(event) => handleJobInfo(event, job)}
                                                                    onBlur={(event) => handleJobInfo(event, job, true)}
                                                                    type='tel'
                                                                    size="small"
                                                                    className={requires[field].billingReason === item.billingReason ? "default-green" : ""}
                                                                    inputProps={{
                                                                        min: 0, step: requires[field].step || 1,
                                                                        style: fieldFailedProps(failedFields[job.id], requires[field].inJob, theme).style,
                                                                    }}
                                                                    InputProps={fieldPendingProps(pendingFields[job.id], requires[field].inJob)}
                                                                    error={fieldFailedProps(failedFields[job.id], requires[field].inJob, theme).error}
                                                                />
                                                            )
                                                        })}
                                                        {
                                                            (convertChecked(item.reqDetails) && splitState !== "left") &&
                                                            <Tooltip arrow title={item.detailDesc || ""} key={'details' + job.id}>
                                                                <TextField
                                                                    disabled={
                                                                        (splitState === "right" && job.checkState >= 2) ||
                                                                        ((!shiftProgress.startTime && !LogiTarUser.current.isContractor() && splitState !== "right") || job.checkState >= 2)
                                                                    }
                                                                    name="jobDetails"
                                                                    label={item?.itemType === "fish" && item?.detailDesc?.toLowerCase() === "rahtikirja" ? "Rahtikirja" : "Lisätiedot"}
                                                                    sx={{ minWidth: "200px" }}
                                                                    value={job.jobDetails}
                                                                    onChange={(event) => handleJobInfo(event, job)}
                                                                    onBlur={(event) => handleJobInfo(event, job, true)}
                                                                    size="small"
                                                                    inputProps={{ style: fieldFailedProps(failedFields[job.id], "jobDetails", theme).style }}
                                                                    InputProps={fieldPendingProps(pendingFields[job.id], "jobDetails")}
                                                                    error={fieldFailedProps(failedFields[job.id], "jobDetails", theme).error}
                                                                />
                                                            </Tooltip>
                                                        }
                                                    </Box>
                                                </Box>

                                                <Tooltip title={job.state < 3 ? "Kuormaa ei ole aloitettu" : null}>
                                                    <Box>
                                                        <Button
                                                            disabled={(!shiftProgress.startTime && !LogiTarUser.current.isContractor()) || job.state !== 3 || splitState === "left"}
                                                            variant="contained"
                                                            onClick={() => jobUnLoaded(job)}>
                                                            Lopeta
                                                        </Button>
                                                    </Box>
                                                </Tooltip>

                                            </Box>
                                        </Box>
                                    )
                                }) : <Typography variant="h5">Ei ajo-ohjelmaa tälle vuorolle.</Typography>
                            }
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Box sx={{ display: 'inline-flex' }}>
                            <FuelPriceCard
                                variant='colors'
                                fuelType={fuelType}
                                horizontal={!smBreakPoint}
                                sx={{ maxWidth: '100%', overflowX: 'auto' }}
                            />
                        </Box>
                    </Box>
                    <JobChangePopup
                        jobs={unseenJobs}
                        open={unseenJobs.length > 0}
                        onClose={() => {
                            jobSeen(unseenJobs);
                        }}
                    />

                    <JobChangePopup
                        jobs={planNotifJobs}
                        open={planNotifJobs.length > 0}
                        onClose={() => {
                            setPlanNotifJobs([]);
                        }}
                        id="planNotifJobs-pop"
                    />

                    {newsPopup &&
                        <NewsAccept news={rows} onClose={setNewSearchTimeStamp} rowUpdate={setNewSearchTimeStamp}></NewsAccept>
                    }

                    {unseenMessagesPopup && (
                        <UnreadMessagesPopup
                            open={(chains.length > 0)}
                            onClose={() => {
                                handleMessagesPopup(true)
                                setUnseenChains(chains)
                                setChains([])
                                setUnseenMessagesPopup(false)
                                StopSound("ring")
                            }}
                        />
                    )}

                    <JobChangePopup
                        jobs={deletedJob}
                        open={deletedJob.length > 0}
                        onClose={() => setDeletedJob([])}
                        deleted={true}
                        future={futureDelete}
                    >
                    </JobChangePopup>

                    {
                        (deletedSplitJob !== null) &&
                        <AlertPop
                            title={`Jako poistettu kuormalta ${deletedSplitJob}`}
                            onClose={() => setDeletedSplitJob(null)}>
                        </AlertPop>
                    }

                    {
                        incompleteShiftActive !== null &&
                        <AlertPop
                            title={`
                                Huomio: Käyttäjällä on aiemmin aloitettu vuoro aktiivisena.\n
                                Päivämäärä: ${format(new Date(incompleteShiftActive.date), "dd.MM.yyyy")}, Vuoro: ${incompleteShiftActive.shift === "A" ? "Aamu" : "Ilta"}.
                                Kalusto: ${incompleteShiftActive.vehicle}. Painamalla OK se lopetetaan, tai paina peruuta jatkaaksesi ilman muutoksia.`}
                            cancellable={true}
                            onClose={(accept) => {
                                if (accept) {
                                    LogitarApi.setUser({ id: LogiTarUser.current.info.id, currentShift: null }).then((r) => {
                                        window.location.reload();
                                    }).catch(e => console.log(e))
                                }
                                setIncompleteShiftActive(null);
                            }}
                        />
                    }

                    {
                        shiftActionPop.open &&
                        <AlertPop
                            title={shiftActionPop.mode === "startTime" ? "Haluatko aloittaa vuoron?" : "Haluatko lopettaa vuoron?"}
                            onClose={(params) => {
                                setShiftActionPop({ open: false, mode: "" })

                                if (!params) {
                                    return
                                }
                                shiftHandle(shiftActionPop.mode)
                            }}
                            cancellable={true}
                        />
                    }

                    {
                        (!jobsSent && tryingToEnd) &&
                        <AlertPop
                            title={"Haluatko lopettaa vuoron ilman lähetystä?"}
                            onClose={(params) => {
                                if (params) {
                                    setTryingToEnd(false)
                                    shiftHandle("endTime", true)
                                    return
                                }
                                setTryingToEnd(false)
                            }}
                            cancellable={true}
                        >
                        </AlertPop>
                    }

                    <JobChangePopup
                        jobs={futureJobs}
                        open={futureJobs.length > 0}
                        onClose={() => { jobSeen(futureJobs, true); setFutureJobs([]) }}
                        future={true}
                    />

                    {endedShift &&
                        <Navigate to={"/workhoursfill"}> </Navigate>
                    }

                    {
                        !!fuelWarningText &&
                        <AlertPop
                            title={fuelWarningText}
                            cancellable
                            onClose={(accept) => {
                                setFuelWarningText(null);
                                if (accept) {
                                    fuelUpdate(true);
                                }
                            }}
                        />
                    }

                </Box >

                {messagesPopup && (
                    <Messages chains={unseenChains} onAccept={handleAcceptChain} onChainOpenChange={chain => setMessageChainOpen(chain ? chain.id : null)} waitToAccept={messagesWaitToAccept} driverMode={true} />)
                }

                {
                    // Subscribe to events, seperate events for messages
                    <>
                        <LogitarEventProvider
                            subscriptions={["job", "drivershift", "jobsplit"]}
                            onEvent={(source, data, id) => {
                                // Push to events reference
                                eventsRef.current.push({ source: source, data: data, id: id });
                                setEvents([...eventsRef.current]);
                            }}
                            onConnect={() => {
                                // Trigger fetch immediately
                                setCount(count + 1);
                                // Clear manual fetch interval
                                if (manualFetchInterval.current) {
                                    clearInterval(manualFetchInterval.current);
                                    manualFetchInterval.current = null;
                                }
                            }}
                            onDisconnect={() => {
                                // Start manual fetch interval
                                if (!manualFetchInterval.current) {
                                    manualFetchInterval.current = setInterval(() => {
                                        setCount(count + 1);
                                    }, 15000);
                                }
                            }}
                        />
                        <LogitarEventProvider
                            subscriptions={["message"]}
                            onEvent={(s, d, i) => setMessageEvents([...messageEvents, { source: s, data: d, id: i }])}
                            onDisconnect={() => {
                                console.log("Message events disconnected")
                                setUnseenMessagesPopup(false);
                                setMessageChainOpen(null);
                            }}
                            onConnect={() => setCount(count + 1)}
                        />
                    </>
                }

            </Stack>

        )
    }

    return (

        <Stack
            spacing={{ xs: 2, sm: 1 }}
            direction="column"
            useFlexGap
            flexWrap="wrap"
        >
            <ErrorBanner errorTypes={['browserOffline', 'apiCallFailed']} showRefreshButton />

            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <Box sx={mainBoxStyle}>
                    <form onSubmit={handleDriver}>
                        <Typography sx={{ mr: 2, pt: 1 }}>Auto:</Typography>
                        <Select
                            disabled={rows.length > 0}
                            value={vehicleNumber}
                            onChange={e => {
                                setVehicleNumber(e.target.value);
                                LogiTarUser.current.setVehicle(vehicles.find(v => e.target.value === v.id) || null);
                                LogiTarUser.current.triggerChange();
                                setMessagesPopup(false);
                                setPrevLatestId(0);
                            }}
                            sx={{ minWidth: '200px' }}
                            required={true}
                        >
                            {
                                vehicles.map((vehicle) => {
                                    return <MenuItem key={"sel-veh-" + vehicle.id} value={vehicle.id}>{vehicle.id}: {vehicle.licenseNumber}</MenuItem>
                                })
                            }
                        </Select>
                        <Button sx={{ height: 40 }} disabled={vehicleNumber === "" || !vehicleNumber} variant="contained" type="submit">Aloita</Button>
                    </form>

                </Box>

                <Box
                    sx={{ ml: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', p: 1, overflowX: 'hidden' }}
                >
                    {
                        !!vehicleMonitor &&
                        <ServiceMonitorCard
                            data={vehicleMonitor}
                            disableMonitorEdit
                            requestRefresh={() => { setRefreshMonitors(Date.now()) }}
                        />
                    }
                    {
                        !!vehicleMonitorTrailer &&
                        <ServiceMonitorCard
                            data={vehicleMonitorTrailer}
                            disableMonitorEdit
                            requestRefresh={() => { setRefreshMonitors(Date.now()) }}
                        />
                    }
                </Box>

                {(!!vehicleMonitor || !!vehicleMonitorTrailer) && (
                    <Box
                        sx={{ ml: 5, mt: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', p: 1, overflowX: 'hidden' }}
                    >
                        <ColorInfo colors={[
                            { class: "row-default-green", info: "Huolto tilattu" },
                            { class: "row-default-yellow", info: "Huolto/katsastus lähellä" },
                            { class: "row-default-red", info: "Huolto/katsastus ylittynyt" },
                        ]} />
                    </Box>
                )}

            </Box>


            {newsPopup &&
                <NewsAccept news={rows} onClose={setNewSearchTimeStamp} rowUpdate={setNewSearchTimeStamp}></NewsAccept>
            }

            {unseenMessagesPopup && (
                <UnreadMessagesPopup
                    open={(chains.length > 0)}
                    onClose={() => {
                        handleMessagesPopup(true)
                        setUnseenChains(chains)
                        setChains([])
                        setUnseenMessagesPopup(false)
                        StopSound("ring")
                    }}
                />
            )}

            {messagesPopup && (
                <Messages chains={unseenChains} onAccept={handleAcceptChain} onChainOpenChange={chain => setMessageChainOpen(chain ? chain.id : null)} waitToAccept={messagesWaitToAccept} driverMode={true} />)
            }

        </Stack >

    )
} 