import { Autocomplete, Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, Divider, FormControl, FormControlLabel, FormLabel, FormGroup, IconButton, InputAdornment, InputLabel, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, Paper, Radio, RadioGroup, Select, Switch, Tab, Tabs, TextField, TextareaAutosize, ToggleButton, ToggleButtonGroup, Tooltip, Typography, createTheme, useTheme } from '@mui/material'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import LogitarApi from '../api/LogitarApi';
import { exitContainerStyle, exitIconStyle } from '../styles/styles';
import { ArrowDropDown, Clear, Close, Edit, FactCheck, FileUpload, Link as LinkIcon, Search } from '@mui/icons-material';
import { CustomPopper, Loader, getTime } from '../misc/InternalFeatures';
import PopupScreen from './PopupScreen';
import AreaCreate from './AreaCreate';
import Config from '../config/Config';
import { useSnackbar } from 'notistack';
import FileManager from './FileManager';
import AreaEdit from './AreaEdit';
import BasicTypeEdit from './BasicTypeEdit';
import ItemTalenomVariantTable from './ItemTalenomVariantTable';
import { set } from 'date-fns';
import { Link } from 'react-router-dom';

const fishOptions = Config.getBranch() === 'konnekuljetus' || Config.getBranch() === 'softrain';

export const units = [
    {
        value: '1',
        label: 'Tonnit',
        req: 'reqTons',
    },
    {
        value: '2',
        label: 'M3',
        req: 'reqM3',
    },
    {
        value: '3',
        label: 'kpl',
        req: 'reqCount',
    },
    {
        value: '4',
        label: 'Tunnit',
        req: 'reqHours',
        onlyFish: true,
    },
    {
        value: '5',
        label: 'Kilometrit',
        req: 'reqKm',
        onlyFish: true,
    }
];

const tonOptions = [
    {
        value: 0,
        label: 'Ei pyydetä',
    },
    {
        value: 1,
        label: 'Taara/Br',
    },
    {
        value: 2,
        label: 'Netto',
    }
]

const shortFieldStyle = {
    width: 140
}

const mediumFieldStyle = {
    width: 244
}

const longFieldStyle = {
    width: 496

}

const rowStyle = { display: "flex", flexDirection: "row" }
const columnStyle = { display: "flex", flexDirection: "column" }
const dialogTextStyle = {
    display: "flex", justifyContent: "space-between", my: 1
}

////////////
const subLength = 3

const itemFields = {
    client: { name: "Asiakaskoodi", length: 3, required: true },
    //maybe client as well
    departure: { name: "Lastaus", length: 4, sub: true, required: true },
    //SUB
    arrival: { name: "Purku", length: 4, sub: true, required: true },
    //SUB
    cargoType: { name: "Tuote", length: 4, required: true },
    basicType: { name: "Tyyppi", length: 2 }
}

const itemHeaders = [
    "Asiakas",
    "Lastaus",
    "Lastaus Alialue",
    "Purku",
    "Purku Alialue",
    "Tuote",
    "Tyyppi"
]

const checkboxFields = [
    { field: "reqCount", headerName: "Kappalemäärä" },
    { field: "reqM3", headerName: "Kuutiot" },
    { field: "reqTons", headerName: "Taara/br", altHeader: "Netto" },
    { field: "reqHours", headerName: "Tunnit" },
    { field: "reqWaitTime", headerName: "Odotus" },
    { field: "reqKm", headerName: "Kilometrit" },
    { field: "reqDetails", headerName: "Lisätiedot" },
]

const fishCheckboxFields = [
    { field: "fishReqFishStatus", headerName: "Kalojen kunto", setFor: "cargo" },
    { field: "fishReqLoadTime", headerName: "Lastauksen kesto", setFor: "cargo" },
    { field: "fishReqUnloadTime", headerName: "Purun kesto", setFor: "cargo" },
    { field: "fishReqWashTime", headerName: "Pesun kesto", setFor: "wash" },
    { field: "fishReqWashLocation", headerName: "Pesupaikka", setFor: "wash" },
]

const setFields = [
    { field: "setMWh", headerName: "MWh" }
]

const checkIfInSubs = (arr, search) => {

    if (!arr) return false

    for (let i = 0; i < arr.length; i++) {
        if (arr[i].subName.toLowerCase().includes(search.toLowerCase())) return true
    }
}

const defaultSelections = { client: false, cargoType: false, basicType: false, departure: false, arrival: false }


export default function ItemEdit({ newItem, editItem, rowUpdate, popupOpen, detailOptions }) {

    const [item, setItem] = useState(editItem)
    //If not creating a new item, format id and itemNumber to be ready
    const [postItem, setPostItem] = useState((!newItem) ? { id: editItem.id } : { ...editItem })
    const [anchorEl, setAnchorEl] = useState(null);

    const [itemSelections, setItemSelections] = useState(defaultSelections)

    const [selectOpt, setSelectOpt] = useState({ client: [], departure: [], basicType: [], arrival: [], cargoType: [] })

    const [labelSelection, setLabel] = useState(defaultSelections)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [checkMode, setCheckMode] = useState(false)

    const [editArea, setEditArea] = useState(/**@type {undefined|null|number}*/(undefined));
    const editAreaType = useRef(/**@type {number}*/(0));
    const createdArea = useRef(/**@type {undefined|null|number}*/(undefined));

    const [basicTypeEdit, setBasicTypeEdit] = useState(false);
    const [editingBasicType, setEditingBasicType] = useState(null);
    const [basicTypeContextAnchor, setBasicTypeContextAnchor] = useState(null);

    const [itemNumber, setItemNumber] = useState()

    const [subs, setSubs] = useState({ departure: null, arrival: null })

    const [search, setSearch] = useState("")

    const [searchFrom, setSearchFrom] = useState()

    const [loading, setLoading] = useState(true)

    const [products, setProducts] = useState([]);
    const [talenomProduct, setTalenomProduct] = useState(null);
    const [talenomVariants, setTalenomVariants] = useState(item.talenomVariants || []);
    const [rememberedTalenomVariants, setRememberedTalenomVariants] = useState(item.talenomVariants || []);
    const [talenomVariantView, setTalenomVariantView] = useState(/** @type {'single'|'multiple'} */((item.talenomVariants?.length || 0) === 0 ? 'single' : 'multiple'));

    const [saveMode, setSaveMode] = useState(null);
    const formRef = useRef();

    const theme = useTheme();

    const isFishItem = item.itemType === 'fish';

    const displayedOptions = useMemo(() => {

        let tempOpt = {}

        if (!searchFrom) {

            Object.keys(defaultSelections).forEach((key) => {
                if (!selectOpt[key] || selectOpt[key].length < 1) {
                    tempOpt[key] = []
                    return
                }
                tempOpt[key] = selectOpt[key].filter((option) => option.name.toLowerCase().includes(search.toLowerCase()))
            })

            return tempOpt
        }

        //Maybe not the best way (deep copy)
        tempOpt = JSON.parse(JSON.stringify({ ...selectOpt }))

        if (Object.keys(subs).includes(searchFrom)) {
            tempOpt[searchFrom].forEach((mainArea, i, arr) => {
                if (mainArea.subAreas) {
                    mainArea.subAreas = mainArea.subAreas.filter(sub => sub.subName.toLowerCase().includes(search.toLowerCase()))
                    if (mainArea.subAreas.length < 1) {
                        arr[i] = { name: "" }
                    }
                }
            })
        }

        tempOpt[searchFrom] = tempOpt[searchFrom].filter((option) =>
            option.name.toLowerCase().includes(search.toLowerCase()) ||
            checkIfInSubs(option.subAreas, search)
        )

        return tempOpt
    },
        [search, selectOpt]
    );

    const updateBasicTypes = (newId) => {
        LogitarApi.getBasicTypes().then(r => {
            if (r.status && r.basicTypes) {
                const sortedBasicTypes = r[0].basicTypes.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                setSelectOpt(prev => ({ ...prev, basicType: sortedBasicTypes }));
                const newBasicType = r.basicTypes.find(type => type.id === newId);
                if (newBasicType) {
                    itemOptionSelection(newBasicType, "basicType");
                    setLabel(prev => ({ ...prev, basicType: newBasicType.id }));
                }
            };
        }).catch(err => {
            enqueueSnackbar("Tyyppien päivittäminen epäonnistui", { variant: "error" });
            console.error(err);
        })
    }

    const getAreaInfos = (area, field) => {
        return subs[area] ? `${subs[area][`sub${field}`]}` : itemSelections[area][field.toLowerCase()]
    }

    const getItemLabel = (field) => {
        return itemSelections[field].name
    }

    const getFields = (arr) => {

        let fieldString = ""

        arr.forEach((check) => {

            if (Boolean(Number(item[check.field]))) {

                if (item[check.field] === 2 && check.altHeader) {
                    fieldString += check.altHeader
                } else {
                    fieldString += check.headerName
                }

                fieldString += ", "
            }
        })

        return fieldString.substring(0, fieldString.length - 2)
    }

    const columns = useMemo(() => [
        { field: "id", headerName: "ID numero", },
        { field: "active", headerName: "Aktiivinen", valueGetter: (val) => val == 1 ? "Kyllä" : "Ei" },
        { field: "itemNumber", headerName: "Nimikenumero", },
        { field: "name", headerName: "Nimike", },
        { field: "shortName", headerName: "Lyhennetty nimike" },
        { field: "client", headerName: "Asiakaskoodi", itemField: true, valueGetter: getItemLabel },
        { field: "cargoType", headerName: "Tuote", itemField: true, valueGetter: getItemLabel, },
        { field: "basicType", headerName: "Tyyppi", itemField: true, valueGetter: getItemLabel },
        { field: "departure", headerName: "Lastauspaikka", itemField: true, valueGetter: getItemLabel },
        { field: "loadingAddress", headerName: "Lastauspaikan osoite", valueGetter: () => getAreaInfos("departure", "Address") },
        { field: "loadingCoord", headerName: "Lastauspaikan koordinaatit", valueGetter: () => getAreaInfos("departure", "Coordinates") },
        { field: "arrival", headerName: "Purkupaikka", itemField: true, valueGetter: getItemLabel },
        { field: "unloadAddress", headerName: "Purkupaikan osoite", valueGetter: () => getAreaInfos("arrival", "Address") },
        { field: "unloadCoord", headerName: "Purkupaikka koordinaatit", valueGetter: () => getAreaInfos("arrival", "Coordinates") },
        { field: "billingReason", headerName: "Laskutusperuste", valueGetter: (unit = 1) => (units[unit - 1].label) },
        { field: "kilometres", headerName: "Kilometrit" },
        { field: "hours", headerName: "Tuntiarvio", valueGetter: getTime },
        { field: "warrantyLoad", headerName: "Takuukuorma" },
        { field: "checkboxFields", headerName: "Pyydettävät tiedot", valueGetter: () => isFishItem ? getFields([...fishCheckboxFields, ...checkboxFields]) : getFields(checkboxFields) },
        { field: "detailDesc", headerName: "Lisätietojen kuvaus" },
        { field: "setFields", headerName: "Täytettävät tiedot", valueGetter: () => getFields(setFields) },
        { field: "notes", headerName: "Ohjeet kuljettajalle" },
    ], [postItem])

    const handleChange = (e) => {

        if (!e) return

        console.log(e.target.name, e.target.type, e.target.value)

        if (e.target.name == "file") {
            return
        }

        // Sending empty string for decimals causes db error, so send null instead
        if (e.target.value === "" && (e.target.name === "kilometres" || e.target.name === "warrantyLoad")) {
            setItem((prev) => ({ ...prev, [e.target.name]: null }));
            return;
        }

        // Force reqTons if warrantyLoad is set
        if (e.target.name === "warrantyLoad" && Number(e.target.value) > 0) {
            if (item.reqTons < 1) setItem((prev) => ({ ...prev, reqTons: 1 }));
            setItem((prev) => ({ ...prev, [e.target.name]: e.target.value }));
            return;
        }
        if (e.target.name === "reqTons" && Number(e.target.value) === 0 && Number(item.warrantyLoad) > 0) {
            enqueueSnackbar("Pyydettävä tieto Tonnit on pakollinen koska Takuukuorma on asetettu", { variant: "warning" });
            return;
        }

        // Don't require billingReason or related requirement for fish wash items
        if (!(isFishItem && item.fishType === "wash")) {
            // When setting billingReason, automatically enable matching requirement
            if (e.target.name === "billingReason") {
                setItem((prev) => ({ ...prev, [e.target.name]: e.target.value }));
                const billingReasonReq = units.find(u => Number(u.value) === Number(e.target.value));
                if (billingReasonReq) setItem((prev) => ({ ...prev, [billingReasonReq.req]: 1 }));
                return;
            }
    
            // Prevent disabling requirement related to billingReason
            if (units.some(u => u.req === e.target.name)) {
                const billingReasonReq = units.find(u => u.req === e.target.name);
                if (Number(item.billingReason) === Number(billingReasonReq.value) && (Number(e.target.value) === 0 || e.target.checked === false)) {
                    enqueueSnackbar(`Laskutusperuste on ${billingReasonReq.label}, joten pyydettävä tieto on pakollinen`, { variant: 'warning' });
                    return;
                }
            }
        }

        // When setting fishType, automatically set relevant requires
        if (isFishItem && e.target.name === "fishType") {
            setItem((prev) => ({ ...prev, [e.target.name]: e.target.value }));
            const newFishReqValues = {};
            if (e.target.value === "cargo") {
                fishCheckboxFields.forEach(f => {
                    if (f.setFor === "cargo") newFishReqValues[f.field] = 1;
                    else newFishReqValues[f.field] = 0;
                })
                newFishReqValues["reqDetails"] = 1;
                newFishReqValues["detailDesc"] = "Rahtikirja";
            } else if (e.target.value === "wash") {
                fishCheckboxFields.forEach(f => {
                    if (f.setFor === "wash") newFishReqValues[f.field] = 1;
                    else newFishReqValues[f.field] = 0;
                })
                newFishReqValues["reqDetails"] = 0;
                newFishReqValues["detailDesc"] = "";
                if (item.billingReason) {
                    const billingReasonReq = units.find(u => Number(u.value) === Number(item.billingReason));
                    if (billingReasonReq) newFishReqValues[billingReasonReq.req] = 0;
                }
            }
            setItem((prev) => ({ ...prev, ...newFishReqValues }));
            return;
        }

        if (e.target.type === "checkbox") {
            setItem((prev) => ({ ...prev, [e.target.name]: e.target.value == 1 ? 0 : 1 }))
        } else {
            setItem((prev) => ({ ...prev, [e.target.name]: e.target.value }))
        }
    }

    const handleProductNumberChange = (v) => {
        setTalenomProduct(v)
        setItem((prev) => ({ ...prev, talenomProduct: v ? v.id : null }));
    }

    //Update selected clients fields
    const updateFields = (clientValue = null) => {

        setLoading(true);

        Promise.all([
            () => LogitarApi.getAreas({ extent: 'all', client: clientValue || itemSelections.client["id"] }),
            () => LogitarApi.getCargoTypes({ client: clientValue || itemSelections.client["id"] })
        ].map((c) => c())).then((r) => {
            const dockingAreas = r[0].areas.map((a) => ({ ...a, subAreas: a.subAreas.length > 0 ? a.subAreas : null }))
            const cts = r[1].cargoTypes.map((ct) => ({ ...ct, name: ct.label }))

            const sortedCargoTypes = cts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            const sortedDeparture = dockingAreas
                .filter((da) => da.type === 0)
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            const sortedArrival = dockingAreas
                .filter((da) => da.type === 1)
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

            setSelectOpt(prev => ({ ...prev, cargoType: sortedCargoTypes, departure: sortedDeparture, arrival: sortedArrival }))

            if (createdArea.current) {
                const carea = dockingAreas.find((a) => a.id === createdArea.current);
                if (carea) {
                    itemOptionSelection(carea, editAreaType.current === 0 ? "departure" : "arrival");
                    setLabel((prev) => ({ ...prev, [editAreaType.current === 0 ? "departure" : "arrival"]: carea.id }));
                }
                createdArea.current = undefined;
            }
            setLoading(false);
        })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar(
                    (<Box>
                        <Box>Asiakkaan linkityksiä ei löytynyt!</Box>
                        <Box>Asiakkaalla pitää olla linkitettynä vähintään yksi alue ja tuote.</Box>
                    </Box>),
                    { variant: "error", autoHideDuration: 10000 }
                );
                setSelectOpt(prev => ({ ...prev, cargoType: [], departure: [], arrival: [] }));
            })
    }

    useEffect(() => {

        LogitarApi.getTalenomData("products").then((res) => {
            setProducts(res.products);

            const stp = res.products.find(e => e.productNumber == item.talenomProduct);
            if (stp) {
                setTalenomProduct({ id: stp.productNumber, label: stp.productName })
            }
        }).catch((err) => {

        })



        if (newItem) {

            Promise.all([
                () => LogitarApi.getClients({ extent: 'list' }),
                () => LogitarApi.getBasicTypes()
            ].map((call) => call()))
                .then((r) => {
                    const sortedBasicTypes = r[1].basicTypes.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                    const options = ({ cargoType: [], client: r[0].clients, basicType: sortedBasicTypes  })

                    setSelectOpt(prev => (options))
                    setLoading(false)
                    setItemNumber([])
                })
        } else {

            Promise.all([
                () => LogitarApi.getClients({ extent: 'list' }),
                () => LogitarApi.getCargoTypes({ client: editItem.client }),
                () => LogitarApi.getAreas({ extent: 'all', client: editItem.client }),
                () => LogitarApi.getBasicTypes()
            ].map((call) => call()))
                .then((r) => {
                    const dockingAreas = r[2].areas.map((a) => ({ ...a, subAreas: a.subAreas && a.subAreas.length > 0 ? a.subAreas : null }))
                    const cts = r[1].cargoTypes.map((ct) => ({ ...ct, name: ct.label }))

                    const sortedCargoTypes = cts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                    const sortedBasicTypes = r[3].basicTypes.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                    const sortedDeparture = dockingAreas
                        .filter((da) => da.type === 0)
                        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                    const sortedArrival = dockingAreas
                        .filter((da) => da.type === 1)
                        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

                    const newSelectOptions = {
                        cargoType: sortedCargoTypes,
                        client: r[0].clients, 
                        basicType: sortedBasicTypes,
                        departure: sortedDeparture,
                        arrival: sortedArrival
                        };

                    //Split itemNumber into array
                    const formedItemNo = []
                    let lowEnd = 0
                    let subsSelects = {}
                    Object.keys(itemFields).forEach((key) => {

                        const val = itemFields[key]
                        let selection = editItem.itemNumber.substring(lowEnd, lowEnd + val.length)
                        formedItemNo.push(selection)
                        lowEnd += val.length

                        if (val.sub) {
                            let sub = editItem.itemNumber.substring(lowEnd, lowEnd + subLength)
                            formedItemNo.push(sub)
                            lowEnd += subLength
                        }
                    })

                    /**
                     * {
                     *      client:
                     *      cargoType:
                     *      departure:
                     *      departureSub:
                     *      arrival:
                     *      arrivalSub:
                     *      basicType:
                     * }
                    */

                    let selects = { ...defaultSelections }
                    let labels = {}


                    const _f = ['departure', 'arrival']
                    _f.forEach((areaName) => {


                        const area = newSelectOptions[areaName].find((area) => area.id === editItem[areaName])

                        //Should always be set though
                        if (!area) {
                            enqueueSnackbar("Alueen tietoja ei löytynyt, tarkista asiakasyhteys", { variant: "error" })
                            return
                        }
                        selects[areaName] = area
                        labels[areaName] = area.id


                        if (editItem[areaName + 'Sub']) {
                            const sub = newSelectOptions[areaName].find((area) => area.id === editItem[areaName]).subAreas.find((subArea) => subArea.subID === editItem[areaName + 'Sub'])

                            subsSelects = { ...subsSelects, [areaName]: sub }
                        }

                    })

                    setItemNumber(formedItemNo)

                    const fs = ['client', 'cargoType', 'basicType']

                    fs.forEach((field) => {

                        const fieldValue = newSelectOptions[field].find((o) => o.id === editItem[field])

                        if (!editItem[field])
                            return

                        selects[field] = fieldValue
                        labels[field] = fieldValue.id
                    })



                    //Item Selections are set so that the itemNumber is formed from them
                    setItemSelections(selects)
                    //Labels are set for the select components
                    setLabel(labels)
                    //Select Options are for selects to have something to select from
                    setSelectOpt(prev => ({ ...newSelectOptions }))
                    //Subs are set if there are subareas set
                    setSubs(prev => ({ ...prev, ...subsSelects }))
                    setLoading(false)

                }).catch((e) => {
                    console.error(e)
                    enqueueSnackbar("Nimikkeiden tietojen haku epäonnistui", { variant: "error" })
                })
        }


    }, [])

    useEffect(() => {
        setItem((prev) => ({ ...prev, talenomVariants: talenomVariants }));
        setPostItem((prev) => ({ ...prev, talenomVariants: JSON.stringify(talenomVariants) }));
    }, [talenomVariants]);

    /**
     * @brief Compares all selected options to their set length, then forms and returns item number based on selection values
     * @returns {String} Item Number formed from selected options
     */
    const formItemNumber = () => {

        const itemParts = []

        for (const option in itemFields) {

            const field = itemSelections[option]

            let fieldString = ""

            if (field) {

                const differenceLength = itemFields[option].length - String(field.id).length

                fieldString += "0".repeat(differenceLength)

                fieldString += String(field.id)

                itemParts.push(fieldString)

                if (itemFields[option].sub) {

                    fieldString = ""

                    if (!subs[option]) {

                        fieldString += "0".repeat(subLength)

                        itemParts.push(fieldString)
                        continue
                    }

                    const subDiff = subLength - String(subs[option].subID).length

                    fieldString += "0".repeat(subDiff)

                    fieldString += String(subs[option].subID)

                    itemParts.push(fieldString)
                }
            } else {

                itemParts.push("0".repeat(itemFields[option].length))

                if (itemFields[option].sub) itemParts.push("0".repeat(subLength))

            }

        }

        return itemParts
    }

    const itemOptionSelection = (area, option) => {



        if (option === "departure" || option === "arrival") {
            setItem(prev => ({ ...prev, [option]: area.id }))
        }

        setItemSelections((prev) => ({ ...prev, [option]: area }))

    }


    useEffect(() => {

        if (!loading) {
            setItemNumber(formItemNumber())
        }



    }, [itemSelections])


    const clientSelection = (client) => {

        setItemSelections(() => (defaultSelections))

        if (client) {
            updateFields(client.id)
            setLabel((prev) => ({ ...defaultSelections, client: client.id }))

        }
        else {
            setLabel(() => defaultSelections)
        }
    }

    const openCheck = () => {
        setSaveMode(true);

        if (!itemNumber || checkMode || (checkSaveIsValid() == false) || checkComboboxSelectionsNotValid() || checkCheckboxSelectionsNotValid()) {
            enqueueSnackbar("Täytä kaikki pakolliset kentät", { variant: "error" })
            setCheckMode(false)
            return;
        }

        let sentItem = {
            ...item,
            itemNumber: itemNumber.join(""),
            client: itemSelections.client.id,
            cargoType: itemSelections.cargoType.id,
            departureSub: subs.departure ? subs.departure.subID : null,
            arrivalSub: subs.arrival ? subs.arrival.subID : null,
            basicType: itemSelections.basicType ? itemSelections.basicType.id : null,
        }

        setItem(sentItem)

        console.log(sentItem)

        setPostItem(sentItem)
        setCheckMode(true)
    }

    const saveItem = (sendingItem) => {

        if (sendingItem.files) {
            sendingItem.files.forEach((e) => {
                if (!String(e.id).includes("NEW-")) {
                    // Delete data if it's not a new file
                    delete e.data;
                }
            })
        }

        // Check if talenom variants are set and it's not a string already and stringify them
        if (sendingItem.talenomVariants && typeof sendingItem.talenomVariants !== 'string') {
            sendingItem.talenomVariants = JSON.stringify(sendingItem.talenomVariants);
        }

        LogitarApi.setItem(sendingItem).then((result) => {
            if (result.status) {
                rowUpdate(prev => (prev + 1))
                popupOpen(false)
                enqueueSnackbar("Nimike tallennettu")
            }
            else {
                enqueueSnackbar("Virhe nimikken tallennuksessa")
            }
        })
    }

    const checkComboboxSelectionsNotValid = () => {
        if (itemSelections.client == false
            || itemSelections.cargoType == false
            || itemSelections.departure == false
            || itemSelections.arrival == false
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    const checkCheckboxSelectionsNotValid = () => {
        if (newItem == true && saveMode == null) return false;
        if (isFishItem) return ![...fishCheckboxFields, ...checkboxFields].some(checkbox => item[checkbox.field] > 0);
        return !checkboxFields.some(checkbox => item[checkbox.field] > 0);
    };

    const checkIsEmpty = (value) => {
        if (newItem == true && saveMode == null) return false;
        if (!value) {
            return true;
        }
        if (value && value.length < 1) {
            return true;
        }
        return false;
    };

    const checkSaveIsValid = () => {
        if (item.shortName == null || item.shortName.length < 1) return false;
        if (item.hours == null || item.hours.length < 1) return false;
        if (item.billingReason == null || item.billingReason.length < 1) return false;
        if (item.itemType === "fish" && item.fishType == null) return false;
        return true;
    }

    return (
        <PopupScreen
            title={newItem ? "Nimikkeen luonti" : "Nimikkeen muokkaus"}
            onClose={() => popupOpen(false)}
            onSave={() => openCheck()}
            saveIcon={
                <>
                    <FactCheck />
                    Tarkista
                </>
            }
            draggable
            // saveProps={{
            //     disabled: !itemNumber || checkMode
            // }}
            saveProps={{ disabled: loading && newItem == false }}
        >
            <Box>
                <form style={{}} onChange={handleChange} ref={formRef}>
                    <Box sx={{
                        "& .MuiTextField-root": {},
                        "& .MuiOutlinedInput-root": { height: 40 },
                        "& .MuiBox-root": { my: 1.5 },
                        "& .MuiFormControl-root": { mr: 1 },
                        "& .MuiTypography-root": { ml: 0 }
                    }}>

                        <Box sx={{ ...rowStyle, alignItems: "center", "& .MuiTypography-root": { mx: 1 } }}>
                            {item && item.id &&
                                <>
                                    <Typography>
                                        ID: {item.id}
                                    </Typography>
                                    <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                </>
                            }
                            {fishOptions &&
                            <Box sx={rowStyle} style={{ marginTop: 0, marginBottom: 0 }}>
                                <ToggleButtonGroup color='primary' exclusive value={item.itemType}
                                    onChange={(_e, v) => {
                                        if (!v) return;
                                        handleChange({ target: { name: "itemType", value: v } })
                                    }}
                                >
                                    <ToggleButton value="normal">Normaali</ToggleButton>
                                    <ToggleButton value="fish">Kala</ToggleButton>
                                </ToggleButtonGroup>
                                <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                            </Box>
                            }
                            <Typography>Passiivinen</Typography>
                            <Switch value={item.active} checked={Boolean(item.active)} onChange={handleChange} name="active"></Switch>
                            <Typography>Aktiivinen</Typography>
                            <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                            <Typography label="Nimikenumero">
                                Nimikenumero:
                            </Typography>



                            {itemNumber ?
                                itemNumber.map((field, key) => {

                                    return (
                                        <Tooltip key={key} title={itemHeaders[key]}>
                                            <Typography>{field}</Typography>
                                        </Tooltip>

                                    )
                                })
                                : <Loader width={35} height={10}></Loader>}

                        </Box>

                        <Box sx={rowStyle}>
                            <TextField label="Nimike" name="name" value={item.name} sx={longFieldStyle} ></TextField>
                            <TextField label="Lyhennetty nimike" name="shortName" value={item.shortName} sx={longFieldStyle} required aria-required error={checkIsEmpty(item.shortName)}></TextField>
                        </Box>

                        <Box sx={{ ...rowStyle, maxWidth: 1200, flexWrap: "wrap", "& .MuiFormControl-root": { mb: 1 } }}>
                            {/**itemnumber fields map */}
                            {Object.keys(itemSelections).map((option, key) => {

                                const areaSwitch = (option === "departure" || option === "arrival")
                                const areaType = (option === "departure" ? 0 : 1);
                                const clientSwitch = (option === "client")
                                const basicTypeSwitch = (option === "basicType");

                                const fieldStyle = (areaSwitch || clientSwitch) ? longFieldStyle : mediumFieldStyle

                                return (
                                    <FormControl key={key}>
                                        <FormGroup row sx={fieldStyle}>
                                            <InputLabel error={itemFields[option].required && !loading && checkIsEmpty(labelSelection[option])} >{itemFields[option].name} {itemFields[option].required && '*'}</InputLabel>
                                            <Select

                                                // disabled={(!itemSelections.client && !clientSwitch && newItem) || (!newItem && clientSwitch)}
                                                disabled={(!itemSelections.client && !clientSwitch && newItem)}
                                                error={itemFields[option].required && !loading && checkIsEmpty(labelSelection[option])}

                                                name={option}
                                                sx={{ flex: 1 }}
                                                label={itemFields[option].name}
                                                // onChange={!clientSwitch ? (e) => setLabel((prev) => ({ ...prev, [e.target.name]: e.target.value })) : () => { }}         
                                                onChange={(e) => {
                                                    if (e.target.value === "skip") return;
                                                    setLabel((prev) => ({ ...prev, [e.target.name]: e.target.value }));
                                                }}
                                                value={labelSelection[option]}

                                                MenuProps={{
                                                    autoFocus: false,
                                                    style: {
                                                        maxHeight: 550
                                                    }
                                                }}
                                                IconComponent={loading && !newItem ? () => (<CircularProgress size={20} sx={{ mr: 1 }} />)
                                                    : () => (<ArrowDropDown sx={{ mr: 1 }} />)}

                                                renderValue={(val) => {
                                                    if (areaSwitch && subs[option]) {
                                                        return `${itemSelections[option].name} - ${subs[option].subName}`
                                                    }

                                                    return itemSelections[option].name
                                                }}
                                                onClose={() => { setSearch(""); setSearchFrom("") }}
                                                onOpen={() => setSearchFrom(option)}
                                                open={basicTypeSwitch ? basicTypeEdit ? false : searchFrom === "basicType" : undefined}
                                            >
                                                <ListSubheader>
                                                    <TextField
                                                        size='small'
                                                        fullWidth
                                                        autoFocus
                                                        placeholder='Syötä kenttään etsiäksesi...'
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Search />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        onChange={(e) => setSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key !== "Escape") {
                                                                // Prevents autoselecting item while typing (default Select behaviour)
                                                                e.stopPropagation();
                                                            }
                                                        }}
                                                    />
                                                </ListSubheader>


                                                <MenuItem value={false} onClick={clientSwitch ? () => clientSelection(false) : () => itemOptionSelection(false, option)} sx={{ color: "red" }}>Ei valintaa</MenuItem>

                                                {areaSwitch &&
                                                    <MenuItem
                                                        onClick={() => { setEditArea(null); editAreaType.current = areaType; }}
                                                        sx={{ color: theme.palette.success.main }}
                                                        value={"skip"}
                                                    >
                                                        Uusi Alue
                                                    </MenuItem>
                                                }

                                                {basicTypeSwitch &&
                                                    <MenuItem
                                                        onClick={() => setBasicTypeEdit(true)}
                                                        sx={{ color: theme.palette.success.main }}
                                                        value={"skip"}
                                                    >
                                                        Uusi Tyyppi
                                                    </MenuItem>
                                                }

                                                {displayedOptions[option] &&
                                                    displayedOptions[option].map((value, key) => {
                                                        const items = []

                                                        if (value.subAreas) {
                                                            items.push(<ListSubheader key={key} sx={{ fontWeight: "bold", fontSize: 16 }}>{value.name}</ListSubheader>)
                                                            value.subAreas.map((sub, subKey) => {
                                                                items.push(
                                                                    <MenuItem
                                                                        onClick={() => {
                                                                            setSubs(prev => ({ ...prev, [option]: sub }))
                                                                            itemOptionSelection(value, option)
                                                                        }}
                                                                        sx={{ fontSize: 11, textIndent: 16 }}
                                                                        key={subKey + 100}
                                                                        value={sub.subID}>

                                                                        {value.name} - {sub.subName}
                                                                    </MenuItem>
                                                                )
                                                            })
                                                            return items
                                                        }
                                                        items.push(
                                                            <MenuItem
                                                                onClick={() => {
                                                                    if (areaSwitch)
                                                                        setSubs(prev => ({ ...prev, [option]: false }));

                                                                    if (clientSwitch)
                                                                        clientSelection(value)

                                                                    itemOptionSelection(value, option)
                                                                }}
                                                                key={key} sx={{ fontWeight: "bold" }}
                                                                onContextMenu={basicTypeSwitch ? (e) => {
                                                                    e.preventDefault();
                                                                    setEditingBasicType({ id: value.id, name: value.name });
                                                                    setBasicTypeContextAnchor(e.currentTarget);
                                                                } : undefined}
                                                                value={value.id}>
                                                                {value.name}
                                                            </MenuItem>
                                                        )
                                                        return (
                                                            items
                                                        )
                                                    })
                                                }

                                            </Select>
                                            {
                                                areaSwitch && (
                                                    <IconButton
                                                        component={Link}
                                                        to={"/areas?id=" + itemSelections[option].id}
                                                        target='_blank'
                                                    >
                                                        <LinkIcon />
                                                    </IconButton>
                                                )
                                            }
                                        </FormGroup>
                                    </FormControl>
                                )
                            })}
                        </Box>

                        {[{ name: "departure", label: "Lastaus" }, { name: "arrival", label: "Purku" }].map((location, key) => (
                            <Box sx={rowStyle} key={key}>
                                <TextField

                                    label={`${location.label}paikan osoite`}
                                    name={`${location.name}Address`}
                                    value={subs[location.name] ? subs[`${location.name}`].subAddress : itemSelections[`${location.name}`].address}
                                    sx={longFieldStyle}
                                    disabled
                                />
                                <TextField
                                    label={`${location.label}paikka: (lat, lng)`}
                                    name={`${location.name}Coord`}
                                    value={subs[location.name] ? subs[`${location.name}`].subCoordinates : itemSelections[`${location.name}`].coordinates}
                                    sx={mediumFieldStyle}
                                    disabled
                                />
                            </Box>
                        ))}

                        <Box sx={rowStyle}>
                            <TextField sx={shortFieldStyle}
                                select
                                value={item.billingReason}
                                name="billingReason"
                                label="Laskutusperuste"
                                onChange={handleChange}
                                required aria-required
                                error={checkIsEmpty(item.billingReason)}
                            >
                                {units.map((unit, key) => unit.onlyFish && !isFishItem ? null : (<MenuItem value={unit.value} key={key}>{unit.label}</MenuItem>))}
                            </TextField>

                            <TextField sx={shortFieldStyle} name="kilometres" type="number" label="Kilometrit" inputProps={{ min: 0, step: 1 }} value={item.kilometres} />
                            <TextField sx={shortFieldStyle} name="hours" type='number' label="Tuntiarvio" inputProps={{ min: 0, step: 0.25 }} value={item.hours} required aria-required error={checkIsEmpty(item.hours)} />
                            <TextField sx={shortFieldStyle} name="warrantyLoad" type='number' label="Takuukuorma" value={item.warrantyLoad} />

                        </Box>

                        {
                            Config.moduleEnabled("talenom") &&
                            <Paper elevation={3} sx={{ p: 0.5, px: 2, pb: 1, maxWidth: 'fit-content' }}>
                                <Box
                                    sx={{ flexDirection: 'row', display: 'flex' }}
                                >
                                    <Typography variant="h6" sx={{ mr: 2 }}>Talenom</Typography>
                                    <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
                                    <Box
                                        sx={{ m: 0, my: "0 !important" }}
                                    >
                                        <Typography variant="caption" sx={{ mr: 2 }}>Yksi laji</Typography>
                                        <Switch
                                            checked={talenomVariantView === 'multiple'}
                                            onChange={(e) => {
                                                setTalenomVariantView(e.target.checked ? 'multiple' : 'single');
                                                if (e.target.checked) {
                                                    setRememberedTalenomVariants(talenomVariants);
                                                    setTalenomVariants([]);
                                                }
                                                else {
                                                    setTalenomVariants(rememberedTalenomVariants);
                                                }
                                            }}
                                        />
                                        <Typography variant="caption" sx={{ mr: 2 }}>Monta lajia</Typography>
                                    </Box>
                                </Box>
                                {
                                    talenomVariantView === 'single' &&
                                    <Autocomplete
                                        disablePortal
                                        PopperComponent={CustomPopper}
                                        id="talenom-product-search"
                                        size='small'
                                        name="talenomProduct"
                                        options={products.map(e => { return { id: e.productNumber, label: e.productName } })}
                                        renderInput={(params) => <TextField {...params} label="Talenom tuotenumero" />}
                                        getOptionLabel={(option) => `${option.id} - ${option.label}`}
                                        sx={{ mr: 1, mt: 1, flex: 1, maxWidth: 585 }}
                                        value={talenomProduct}
                                        onChange={(e, v) => { handleProductNumberChange(v) }}
                                    />
                                }
                                {
                                    talenomVariantView === 'multiple' &&
                                    <ItemTalenomVariantTable
                                        value={talenomVariants}
                                        onChange={(e) => setTalenomVariants(e)}
                                        products={products}
                                    />
                                }
                            </Paper>
                        }
                        
                        {isFishItem &&
                        <FormControl sx={{ mt: 2, ml: 1 }} error={item.itemType === "fish" && checkIsEmpty(item.fishType)}>
                            <FormLabel>Kalakuorman tyyppi*</FormLabel>
                            <RadioGroup name='fishType' value={item.fishType} row>
                                <FormControlLabel value='cargo' control={<Radio />} label='Kuorma' />
                                <FormControlLabel value='wash' control={<Radio />} label='Pesu' />
                            </RadioGroup>
                        </FormControl>
                        }


                        <Box sx={{
                            ...rowStyle, alignItems: "center",
                            '& .MuiFormControlLabel-root': {
                                flexDirection: "row-reverse",
                            },
                            "& .MuiInputBase-input": {
                                boxSizing: "border-box"
                            },
                            flexWrap: "wrap",
                            gap: 1,
                        }}
                            style={{ margin: 0 }}
                        >

                            <Box
                                component="fieldset"
                                sx={{
                                    border: checkCheckboxSelectionsNotValid() ? '2px solid red' : '1px solid #ccc',
                                    padding: 0,
                                    borderRadius: 1,
                                    '& legend': {
                                        ml: 1,
                                        color: checkCheckboxSelectionsNotValid() && 'red', // Change color conditionally
                                        fontSize: 12, // Example additional style
                                    },
                                    maxWidth: 990,
                                    pl: 1,
                                    pb: 0.5,
                                }}
                            >
                                <legend>Pyydettävät tiedot*</legend>
                                {isFishItem && fishCheckboxFields.map((checkbox, key) => {
                                    return (
                                        <FormControlLabel
                                            key={"fish-" + key}
                                            control={
                                                <Checkbox
                                                    sx={{ ml: -1}}
                                                    name={checkbox.field}
                                                    value={item[checkbox.field]}
                                                    checked={item[checkbox.field]}
                                                />
                                            }
                                            label={`${checkbox.headerName}:`}
                                            sx={{ ml: 1 }}
                                        />
                                    )
                                })}
                                {checkboxFields.map((checkbox, key) => {
                                    if (checkbox.field === "reqTons") {
                                        return (

                                            <FormControlLabel

                                                label={`Tonnit:`}
                                                sx={{ ml: 1 }}
                                                key={key}
                                                control={
                                                    <Select
                                                        key={key}
                                                        name={checkbox.field}
                                                        value={item[checkbox.field]}
                                                        // onChange={(e) => setItem(prev => ({ ...prev, reqTons: Number(e.target.value) }))}
                                                        onChange={(e) => handleChange(e)}
                                                        sx={{ ml: 1, width: 120 }}
                                                        size='small'
                                                    >
                                                        {tonOptions.map((option, key) => (
                                                            <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                                                        ))}
                                                    </Select>
                                                }
                                            />

                                        )
                                    }

                                    return (
                                        <FormControlLabel
                                            key={key}
                                            control={
                                                <Checkbox
                                                    sx={{ ml: -1 }}
                                                    name={checkbox.field}
                                                    value={item[checkbox.field]}
                                                    checked={item[checkbox.field]}
                                                />
                                            }
                                            label={`${checkbox.headerName}:`}
                                            sx={{ ml: key === 0 ? 1 : 1 }} // Set margin-left for the first element
                                        />
                                    )
                                })}
                            </Box>

                            <Autocomplete
                                sx={{ ...mediumFieldStyle, mr: 1 }}
                                label={"Lisätiedon kuvaus"}
                                disabled={item["reqDetails"] === 0}
                                name="detailDesc"
                                options={detailOptions}
                                PopperComponent={CustomPopper}
                                onInputChange={handleChange}
                                onChange={(e, v) => setItem(prev => ({ ...prev, detailDesc: v }))}
                                value={item.detailDesc}
                                renderInput={(params) => (<TextField label="Lisätiedon kuvaus" name="detailDesc" {...params}></TextField>)}
                            >
                            </Autocomplete>

                        </Box>

                        <Box sx={{
                            ...rowStyle, alignItems: "center",
                            '& .MuiFormControlLabel-root': {
                                flexDirection: "row-reverse",
                            },

                        }}
                            style={{ marginTop: 0 }}
                        >
                            <Typography sx={{ mr: 3, ml: 1 }}>Täytettävät tiedot:</Typography>
                            {setFields.map((set, key) => (
                                <FormControlLabel key={key}

                                    control={<Checkbox
                                        sx={{ pl: 1 }}
                                        name={set.field}
                                        value={item[set.field]}
                                        checked={item[set.field]} />}
                                    label={`${set.headerName}:`} />
                            ))}

                        </Box>

                        <Box sx={{ ...columnStyle, maxHeight: 200 }}>
                            <TextField
                                name="notes"
                                label="Ohjeet kuljettajalle"
                                multiline
                                rows={4}
                                value={item.notes}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        height: 'auto'
                                    }
                                }}
                            />
                        </Box>

                        <Box sx={{ ...rowStyle, display: 'flex', flex: 1, maxWidth: '30vw' }}>
                            <FileManager
                                sx={{ flex: 1, my: 1 }}
                                files={item.files}
                                onChange={(e) => {
                                    setItem(prev => ({ ...prev, files: e }));
                                }}
                            />
                        </Box>
                    </Box>
                </form>

                {
                    editArea !== undefined &&
                    <AreaEdit
                        info={
                            editArea !== null ?
                            /*TODO: set this to edit an area*/null
                                :
                                {
                                    clients: [itemSelections.client.id],
                                    type: editAreaType.current
                                }
                        }
                        onClose={(area) => {
                            setEditArea(undefined);

                            createdArea.current = area;
                            updateFields();
                        }}
                    />
                }

                {
                    basicTypeEdit &&
                    <BasicTypeEdit value={editingBasicType} onClose={(saved, newId) => {
                        setBasicTypeEdit(false);
                        setEditingBasicType(null);
                        setSearch("");
                        setSearchFrom("");
                        if (saved) {
                            updateBasicTypes(newId);
                        }
                    }} />
                }

                {checkMode &&
                    <PopupScreen
                        onSave={() => saveItem(postItem)}
                        staticButtons
                    >
                        <Box>
                            <Box sx={{ ...exitContainerStyle, pt: 1 }}>
                                <Typography variant="h5" sx={{ fontWeight: "bold" }}>Nimikkeen tarkistus</Typography>
                                <Close sx={exitIconStyle} onClick={() => setCheckMode(false)} />
                            </Box>
                            <Box sx={{
                                width: "650px", "& .MuiOutlinedInput-input": { height: 20, p: 0.3 }
                                , "& .MuiOutlinedInput-root": { width: "auto" },
                                pb: 0, pt: 0
                            }}>

                                {postItem &&
                                    columns.map((data, key) => {

                                        const param = data.itemField ? data.field : item[data.field]

                                        const getValue = data.valueGetter ? data.valueGetter(param) : item[data.field]

                                        return (
                                            <Box key={key} sx={{ ...dialogTextStyle, overflowWrap: "anywhere", textAlign: "left" }}>
                                                <span style={{ flex: 2 }}>{data.headerName}:</span>
                                                <span style={{ textAlign: "left", flex: 3 }}>{getValue}</span>
                                            </Box>

                                        )
                                    })
                                }


                                <Box sx={{ ...dialogTextStyle, overflowWrap: "anywhere", textAlign: "left" }}>
                                    <span style={{ flex: 2 }}>Tiedostot:</span>
                                    <Box sx={{ textAlign: "left", flex: 3 }}>
                                        {
                                            item.files && item.files.map((file, key) => {
                                                return (
                                                    <Typography key={key} sx={{ display: 'block' }}>
                                                        {
                                                            file[">del"] ?
                                                                <s>
                                                                    {file.name}
                                                                </s>
                                                                :
                                                                <span>
                                                                    {file.name}
                                                                </span>
                                                        }
                                                    </Typography>
                                                )
                                            })

                                        }
                                    </Box>
                                </Box>


                            </Box>
                        </Box>
                    </PopupScreen>

                }

            </Box>
            <Menu
                anchorEl={basicTypeContextAnchor}
                open={basicTypeContextAnchor != null}
                onClose={() => setBasicTypeContextAnchor(null)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    setBasicTypeEdit(true);
                    setBasicTypeContextAnchor(null);
                }}>
                    <ListItemIcon><Edit /></ListItemIcon>
                    <ListItemText>Muokkaa tyyppiä</ListItemText>
                </MenuItem>
            </Menu>
        </PopupScreen>

    )
}
